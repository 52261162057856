import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactInputMask from 'react-input-mask';
/**
 * 
 * @param {String} number 
 * @param {String} template 
 * @returns {String}
 */
export const formatNumber = (number, template = '+### (##) ##-##-###') => {
  const re = /#/g;
  const len = template.match(re)?.length ?? -1;

  let i = 0;
  const result =
    number.length < len
      ? "Invalid phone number"
      // eslint-disable-next-line no-return-assign
      : template.replace(re, () => number[i += 1]);
  return result;
};

/**
 * 
 * @param {{ children: React.JSX.Element, disabled: Boolean, value: String | null, setValue: Dispatch<SetStateAction<string | null>>}}  
 * @returns {React.JSX.Element}
 */
export const PhoneMask = ({ children, disabled, value, setValue }) => (
  <ReactInputMask
    mask='+999 (99) 99 99 999'
    value={value}
    onChange={event => setValue(event.target.value)}
    disabled={disabled}
    maskChar=' '
    formatChars={{
      '9': '[0-9]'
    }}
  >
    {() => children}
  </ReactInputMask>
);
