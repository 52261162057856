import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenteredText from './CenteredText'
import { useContekst } from '../../context';

const LoggedInBoundry: React.FC<PropsWithChildren> = ( { children } ) => {
  const context = useContekst();
  const navigate = useNavigate();
  const [ isLoggedIn, setIsLoggedIn ] = useState( false );

  const logout = () => {
    navigate( '/' );
    setIsLoggedIn( false );
  };

  useEffect( () => {
    if ( context?.menuIsVisible ) {
      const data = document.cookie.split( ';' );
      if ( data ) {
        const password = data.filter( el => el.trim().startsWith( 'password=' ) )[ 0 ]?.trim()?.slice( 9 );
        const username = data.filter( el => el.trim().startsWith( 'username=' ) )[ 0 ]?.trim()?.slice( 9 );
        if ( password && username ) {
          setIsLoggedIn( true );
        } else {
          logout();
        }
      } else {
        logout();
      }
    }
  }, [ context ] )

  return (
    <>
      { isLoggedIn && children }
      { !isLoggedIn && (
        <CenteredText>
          Hold a second, we are verifying you 🧐
        </CenteredText>
      ) }
    </> )
};

export default LoggedInBoundry;
