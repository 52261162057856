import { styled } from '@mui/material';

const ApplyButton = styled( 'button' )( ( { disabled, theme } ) => ( {
  border: 0,
  fontSize: 18,
  paddingTop: 5,
  paddingLeft: 15,
  paddingRight: 15,
  fontWeight: 500,
  borderRadius: 8,
  paddingBottom: 5,
  color: 'rgb(999,999,999)',
  backgroundColor: disabled ? 'grey' : '#f2b82f',
  '&:hover': {
    backgroundColor: disabled ? 'grey' : 'rgb(33,45,94)'
  },
  [ `${ theme.breakpoints.down( 'md' ) }` ]: {
    width: '100%'
  },
} ) );

export default ApplyButton;
