import React from 'react';
import { styled } from '@mui/material';

const Footer = styled( 'section' )( {
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#f2b82f',
} );

const Copyright = styled( 'p' )( {
  fontSize: 12,
  paddingTop: 7,
  paddingBottom: 7,
  color: 'rgb(33,45,94)',
  margin: '0 !important',
} );
const currentYear = new Date().getFullYear();

const ApplicationFooter = () => (

  <Footer>
    <Copyright>
      © cloudjob.agency Ukraine {currentYear}
    </Copyright>
  </Footer>
);

export default ApplicationFooter;
