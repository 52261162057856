import { Button, TextField, styled } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import endpoints from '../../requests/endpoints'
import LoginCard from './LoginCard';
import LoginContainer from './LoginContainer';
import { useContekst } from '../../context';
import useFetch from '../../hooks/useFetch';

const StyledForm = styled( 'form' )( {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 20,
  marginRight: 20,
} );

const Login: React.FC = () => {
  document.title = 'Cloudjob agency | Login 🪪';
  const navigate = useNavigate();
  const context = useContekst();
  const fetch = useFetch();

  const createCookie = ( name: String, pwds: String ) => {
    const today = new Date();
    const expire = new Date();
    expire.setTime( today.getTime() + 3600000 * 24 * 15 );
    document.cookie = `username=${ name } expires=${ expire.toUTCString() }`;
    document.cookie = `password=${ pwds }; expires=${ expire.toUTCString() }`;
  }

  const onSubmit = async ( event ) => {
    event.preventDefault();
    const body = {
      username: '',
      password: '',
      _csrf: '',
    };
    body.username = event?.target?.username?.value?.trim();
    body.password = event?.target?.password?.value?.trim();
    if ( !body?.username || !body?.password ) {
      context?.setLoading( false );
      return context?.setError( 'Username and password cannot be empty, please fill in the form' );
    }

    const res: any = await fetch( endpoints.login.url, endpoints.login.method, body, true );

    if ( res.status === 'success' ) {
      createCookie( res?.data?.username, res?.data?.hash );
      navigate( '/dashboard' );
    }

    return undefined;
  };

  return (
    <LoginContainer>
      <LoginCard>
        <StyledForm onSubmit={ onSubmit }>
          <TextField disabled={ context?.loading } label='Login' name='username' />
          <TextField
            disabled={ context?.loading }
            label='Password'
            name='password'
            sx={ { marginTop: '20px' } }
            type='password'
          />
          <Button
            disabled={ context?.loading }
            sx={ { marginTop: '20px' } }
            type='submit'
            variant='outlined'
          >
            Log In
          </Button>
        </StyledForm>
      </LoginCard>
    </LoginContainer>
  );
};

export default Login;
