import {
  AccountCircle,
  BorderColor,
  Dashboard,
  Logout,
  Nightlight,
  Settings,
  Visibility,
  WbSunny,
} from '@mui/icons-material';
import {
  Box,
  Menu as DropDownMenu,
  MenuItem,
  useColorScheme,
} from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { useContekst } from '../../context/index'

const MenuDropDown = ( { anchor, setAnchor } ) => {
  const navigate = useNavigate();
  const context = useContekst();
  const { mode, setMode } = useColorScheme();

  const getColor = ( isActive?: boolean ) => {
    if ( isActive ) {
      return 'rgb(66, 165, 245)';
    }
    return mode === 'light' ? 'rgb(117,117,117)' : '#fff';
  };

  const onLogout = () => {
    document.cookie = 'username=';
    document.cookie = 'password=';
    context.setMenuIsVisible( false );
    setAnchor( null );
    navigate( '/' );
  };

  return (
    <DropDownMenu
      anchorEl={ anchor }
      anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
      keepMounted
      transformOrigin={ { vertical: 'top', horizontal: 'right', } }
      open={ Boolean( anchor ) }
      onClose={ () => setAnchor( null ) }
    >
      <Box sx={ { display: { s: 'none', xs: 'block', md: 'none', lg: 'none' } } }>
        <NavLink
          to='/dashboard'
          style={ ( { isActive } ) => ( {
            color: getColor( isActive ),
            textDecoration: 'none'
          } ) }
        >
          <MenuItem
            color='inherit'
            onClick={ () => {
              setAnchor( null );
              navigate( '/dashboard' );
            } }
          >
            <Dashboard sx={ { mr: 0.5 } } /> Dashboard
          </MenuItem>
        </NavLink>
        <NavLink
          to='/viewer'
          style={ ( { isActive } ) => ( {
            color: getColor( isActive ),
            textDecoration: 'none'
          } ) }
        >
          <MenuItem
            color='inherit'
            onClick={ () => {
              setAnchor( null );
              navigate( '/viewer' )
            } }
          >
            <Visibility sx={ { mr: 0.5 } } /> Viewer
          </MenuItem>
        </NavLink>
        <NavLink
          to='/vacancies'
          style={ ( { isActive } ) => ( {
            color: getColor( isActive ),
            textDecoration: 'none'
          } )
          }
        >
          <MenuItem
            color='inherit'
            onClick={ () => {
              setAnchor( null );
              navigate( '/vacancies' )
            } }
          >
            <BorderColor sx={ { mr: 0.5 } } /> Vacancies
          </MenuItem>
        </NavLink>
        <NavLink
          to='/settings'
          style={ ( { isActive } ) => ( {
            color: getColor( isActive ),
            textDecoration: 'none'
          } )
          }
        >
          <MenuItem
            color='inherit'
            onClick={ () => {
              setAnchor( null );
              navigate( '/settings' )
            } }
          >
            <Settings sx={ { mr: 0.5 } } /> Settings
          </MenuItem>
        </NavLink>
        <MenuItem
          sx={ { color: getColor() } }
          onClick={ () => {
            if ( mode === 'light' ) {
              setMode( 'dark' );
            } else {
              setMode( 'light' );
            }
            setAnchor( null );
          } }
        >
          { mode === 'light' ? <Nightlight sx={ { mr: 0.5 } } /> : <WbSunny sx={ { mr: 0.5 } } /> } Switch theme
        </MenuItem>
        <NavLink
          to='/profile'
          style={ ( { isActive } ) => ( {
            color: getColor( isActive ),
            textDecoration: 'none'
          } )
          }
        >
          <MenuItem
            onClick={ () => {
              navigate( '/profile' );
              setAnchor( null );
            } }
          >
            <AccountCircle sx={ { mr: 0.5 } } /> Profile
          </MenuItem>
        </NavLink>
        <NavLink
          to='/'
          style={ ( { isActive } ) => ( {
            color: getColor( isActive ),
            textDecoration: 'none'
          } )
          }
        >
          <MenuItem onClick={ onLogout }>
            <Logout sx={ { mr: 0.5 } } /> Log out
          </MenuItem>
        </NavLink>
      </Box>
      <Box sx={ { display: { s: 'block', xs: 'none', md: 'block', lg: 'block' } } }>
        <MenuItem
          onClick={ () => {
            navigate( '/profile' );
            setAnchor( null );
          } }
        >
          Profile
        </MenuItem>
        <MenuItem onClick={ onLogout }>
          Log out
        </MenuItem>
      </Box>
    </DropDownMenu>
  );
};

export default MenuDropDown;
