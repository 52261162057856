/* eslint-disable import/no-extraneous-dependencies */
import format from 'date-fns/format';
import differenceInYears from 'date-fns/differenceInYears';

/**
 * 
 * @param {Date} date
 * @returns {'dd.MM.yyyy'}
 */
export const formatServerToClient = date => format(date, 'dd.MM.yyyy');

/**
 * 
 * @param {'yyyy-MM-dd'} date
 * @returns {Data}
 */
export const formatServerToData = date => {
  if (!date || !date?.includes('-')) return ''
  const [year, month, day] = [...date.split('-')];
  const monthIndex = month - 1;
  let cleanDay;
  if (day?.includes('T')) {
    [cleanDay] = day.split('T');
  } else {
    cleanDay = day
  }
  return new Date(year, monthIndex, cleanDay);
};

/**
 * 
 * @param {'yyyy-MM-dd'} date
 * @returns {Number}
 */
export const getAgeFromServerFormat = date => {
  if (!date || !date?.includes('-')) return ''
  const [year, month, day] = date.split('-');
  const today = new Date();
  const birthday = new Date(year, month - 1, day);
  return differenceInYears(today, birthday);
};

/**
 * 
 * @param {'dd.MM.yyyy'} date
 * @returns {Number}
 */
export const getAgeFromClientFormat = date => {
  if (!date || !date?.includes('.')) return ''
  const [day, month, year] = date.split('.');
  const today = new Date();
  const birthday = new Date(year, month - 1, day);
  return differenceInYears(today, birthday);
};

/**
 * 
 * @param {'dd.MM.yyyy'} date
 * @returns {Number}
 */
export const getAgeFromDate = date => {
  const today = new Date();
  const birthday = date;
  return differenceInYears(today, birthday) || 0;
};

/**
 * 
 * @param {'dd.MM.yyyy'} date
 * @returns {'yyyy-MM-dd'}
 */
export const formatClientToServer = date => {
  if (!date || !date?.includes('.')) return ''
  const [day, month, year] = date.split('.');
  return format(new Date(Number(year), Number(month) - 1, Number(day)), 'yyyy-MM-dd');
};

/**
 * 
 * @param {Date} date
 * @returns {'yyyy-MM-dd'}
 */
export const formatDateToServer = date => date.toISOString().slice(0, 19).replace('T', ' ')
