import {styled } from '@mui/material';

const ApplicationMain = styled( 'div' )( {
  backgroundColor: '#e0d9d9',
  maxWidth: 1000,
  width: '100vw',
  height: 'fit-content',
  minHeight: '100%',
} );

export default ApplicationMain;
