import { Card, styled } from '@mui/material';

export default styled(Card)({
  maxWidth: 500,
  maxHeight: 300,
  marginTop: 100,
  marginRight: 20,
  marginLeft: 20,
  paddingRight: 20,
  paddingLeft: 20,
  borderRadius: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  opacity: 0.9,
});
