import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

type propTypes = {
  disabled: boolean,
  setValue: ( val: string ) => void,
  value: string,
  onFocus: () => void,
  onBlur: () => void,
}


const RichTextEditor: React.FC<propTypes> = ( { disabled, value, setValue, onFocus, onBlur } ) => {

  const onTextChange = ( content: string ) => {
    if ( content === '<p><br></p>' ) return;
    setValue( content );
  };


  return (
    <div style={ { gridColumn: '1 / -1', minHeight: 75 } }>
      <SunEditor
        lang='en'
        disable={ disabled }
        setOptions={ {
          buttonList: [
            [ 'undo', 'redo' ],
            [ 'font', 'fontSize', 'formatBlock' ],
            [ 'paragraphStyle', 'blockquote' ],
            [ 'fontColor', 'hiliteColor', 'textStyle' ],
            [ 'removeFormat' ],
            [ 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript' ],
            [ 'outdent', 'indent' ],
            [ 'align', 'horizontalRule', 'list', 'lineHeight' ],
            [ 'table' ],
          ],
        } }
        setContents={ value }
        onFocus={ onFocus }
        onBlur={ onBlur }
        onChange={ onTextChange }
        width='100%'
        height='100%'
      />
    </div>
  )
};

export default React.memo( RichTextEditor );
