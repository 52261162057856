import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import React from 'react';
import endpoints from '../../requests/endpoints';
import { useContekst } from '../../context';
import useFetch from '../../hooks/useFetch';
import { vacancyType } from '../../../types/types';

type propTypes = {
  onClose: ( param?: 'success' ) => void,
  isOpen: boolean,
  selectedRows: [] | null | vacancyType[],
};

const DeleteModal: React.FC<propTypes> = ( { onClose, isOpen, selectedRows } ) => {
  const fetch = useFetch();
  const context = useContekst();

  const onRemove = async () => {
    const body = { id: selectedRows.map( vacancy => vacancy.id ) }
    const request = await fetch(
      endpoints.vacancyDelete.url,
      endpoints.vacancyDelete.method,
      body,
      true,
    )
    if ( request.status === 'success' ) {
      context.setSuccess( 'The vacancy was deleted' )
      onClose( 'success' );
    }
  };

  return (
    <Dialog open={ isOpen } onClose={ onClose }>
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <Typography>
          Do you really want to remove selected vacancy?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => onClose() }>
          Cancel
        </Button>
        <Button onClick={ onRemove }>
          <DeleteForever /> Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
