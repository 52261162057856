import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Typography, styled } from '@mui/material';

const logo = require( '../../assets/logo_cloudjob_trans.png' );


const ApplicationHeader = ( { onGoBack }: { onGoBack?: () => void } ) => {
  const Header = styled( 'div' )( {
    height: 93,
    width: '100vw',
    color: 'black',
    backgroundColor: '#e0d9d9',
    backgroundSize: `600px 93px;`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'center',
    alignItemms: 'center',
    display: 'flex',
  } );
  return (
    <Header>
      { !!onGoBack && (
        <IconButton sx={ { position: 'absolute', top: 25, left: 10 } } onClick={ onGoBack }>
          <ArrowBack />
        </IconButton>
      ) }
      <div style={ {
        maxWidth: 1000,
        width: '100vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
      } }>
        <img src={ logo } alt='cloudjob agency logo' height='120' width='auto' style={ { marginTop: 10, marginLeft: onGoBack ? 50 : 10 } } />
        <Typography
          variant='h4'
          component='h3'
          sx={ { color: 'rgb(22,103,187)', fontSize: { sm: 24, xs: 16, md: 28, lg: 42 } } }
        >
          Your next job starts here
        </Typography>
      </div>
    </Header >
  );
};

ApplicationHeader.defaultProps = {
  onGoBack: undefined
}

export default ApplicationHeader;
