import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { Close, QuestionMark } from '@mui/icons-material';
import { useContekst } from '../../context/index';

const Error: React.FC = () => {
  const context = useContekst();

  const onClose = () => {
    context?.setError( '' );
  };

  return (
    <Dialog open={ !!context?.error }>
      <DialogTitle>Error 🤦🏻‍♂️</DialogTitle>
      <DialogContent>
        <Typography>{ context?.error }</Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title='If you do not understand a meaning of the error or it happens too frequently, please report it to TEST CRM Skype group or contact IT departament directly.'>
          <QuestionMark style={ { marginRight: 'auto' } } />
        </Tooltip>
        <Button
          color='primary'
          onClick={ onClose }
          startIcon={ <Close /> }
          variant='outlined'
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Error;
