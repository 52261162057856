import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import LoginContainer from './LoginContainer';
import LoginCard from './LoginCard';
import CenteredText from '../common/CenteredText';

const StyledLink = styled( Link )( ( { theme } ) => ( {
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark,
  },
} ) );

const App = () => {
  document.title = 'Cloudjob agency | Wellcome! 👋';
  return (
    <LoginContainer>
      <LoginCard>
        <CenteredText variant='h3'>
          Hello and wellcome to Cloudjob agency! 👋
        </CenteredText>
        <CenteredText variant='h6'>
          Please <StyledLink to='/login'>log in</StyledLink>
        </CenteredText>
      </LoginCard>
    </LoginContainer>
  );
};

export default App;
