import { styled } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import ApplyButton from '../common/ApplyButton';

import Application from '../vacancies/Application';
import ApplicationFooter from '../common/ApplicationFooter';
import ApplicationHeader from '../common/ApplicationHeader';
import ApplicationMainContainer from '../common/ApplicationMainContainer';
import endpoints from '../../requests/endpoints';
import Page from '../common/Page';
import useFetch from '../../hooks/useFetch';
import { formatServerToData } from '../../helpers/dateFormatters.js';
import { vacancyType } from '../../../types/types';

const Table = styled( 'table' )( {
  minWidth: '100%',
  marginBottom: '10rem',
} );

const TableRow = styled( 'tr' )( {
  borderWidth: 3,
  paddingTop: 20,
  paddingBottom: 20,
} );

const TableCell = styled( 'td' )( {
  borderTop: '0.25rem solid lightgrey',
  textAlign: 'center',
  color: 'rgb(33,45,94)',
  fontFamily: 'sans-serif',
  fontWeight: '500',
  fontSize: 18,
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
} );

const MainSection = styled( 'div' )( ( { theme } ) => ( {
  marginLeft: '2rem',
  marginRight: '2rem',
  [ theme.breakpoints.down( 'sm' ) ]: {
    marginLeft: 0,
    marginRight: 0,
  },
} ) );

const SellingContainer = styled( 'div' )( ( { theme } ) => ( {
  display: 'flex',
  gap: '1rem',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10rem',
  [ theme.breakpoints.down( 'sm' ) ]: {
    flexDirection: 'column-reverse',
  }
} ) );

const Header = styled( 'div' )( ( { theme } ) => ( {
  backgroundColor: 'rgb(999,999,999)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,
} ) );

const Partner = styled( 'img' )( {
  maxWidth: '10rem',
  maxHeight: '5rem',
  filter: 'grayscale(1)',
  opacity: 0.5,
} )

const Avatar = styled( 'img' )( {
  width: '10rem',
  height: '10rem',
  objectFit: 'cover',
  borderRadius: 8,
} );

const Quote = styled( 'p' )( {
  fontFamily: 'sans-serif',
  color: 'grey',
  fontSize: 18,
  fontWeight: '500',
  maxWidth: '10rem',
  textAlign: 'center',
} );

const RepresentationImg = styled( 'img' )( {
  height: 'auto',
  width: '100%',
  borderRadius: '2rem',
  flex: 1,
  maxWidth: '40rem',
} )

const Engagement = styled( 'p' )( {
  color: 'rgb(33,45,94)',
  fontFamily: 'sans-serif',
  fontWeight: '500',
  fontSize: 18,
  marginRight: '1rem',
  marginTop: 20,
} )

const FullWidthImage = styled( 'img' )( {
  width: '100%',
  minWidth: 1000,
  overflow: 'hidden',
} );

const FullWidthContainer = styled( 'div' )( {
  marginBottom: '5rem',
  marginLeft: '-2rem',
  marginRight: '-2rem',
  position: 'relative',
  zIndex: 0,
  overflow: 'hidden',
} );

const FullWidthCard = styled( 'div' )( {
  position: 'absolute',
  right: '15%',
  top: '10%',
  width: '70%',
  borderRadius: 8,
  backdropFilter: 'blur(20px)',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  boxShadow: '0 1px 12px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  textAlign: 'center',
  padding: 40,
  opacity: 1,
} );

const Title = styled( 'h1' )( {
  position: 'absolute',
  top: -60,
  left: 0,
  fontFamily: 'sans-serif',
  fontWeight: '600',
  fontSize: 28,
  textAlign: 'center',
  width: '100%',
  color: '#fff'
} )

const Description = styled( 'p' )( ( { theme } ) => ( {
  color: 'rgb(33,45,94)',
  fontFamily: 'sans-serif',
  fontWeight: '400',
  fontSize: 16,
} ) );

const AltFooter = styled( 'div' )( {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
} );

const QuoteSection = styled( 'div' )( {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  marginBottom: '10rem',
  gap: '0.5rem',
} );

const Footer = styled( 'div' )( {
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  height: '5rem',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  width: '100%',
  backgroundColor: 'rgb(999,999,999)',
} );

const QuoteContainer = styled( 'div' )( {
  display: 'flex',
  flexDirection: 'column',
} );

const Landing = () => {
  const fetch = useFetch();
  const [ actualVacancies, setActualVacancies ] = useState( [] );
  const [ application, setApplication ] = useState( false );

  const getVacancies = useCallback( async () => {
    setActualVacancies( [ {} ] )
    const res = await fetch( endpoints.vacancyList.url, endpoints.vacancyList.method, undefined, true );
    if ( res.status === 'success' ) {
      const allActiveVacancies = res.data.vacancies.filter( ( el: vacancyType ) => {
        if ( el.isActive || new Date <= formatServerToData( el.closedAt ) ) {
          return true
        }
        return false
      } );
      setActualVacancies( allActiveVacancies.slice( 0, 5 ) );
    }
  }, [] );

  useEffect( () => {
    if ( !actualVacancies.length ) {
      getVacancies();
    }
  }, [ actualVacancies, getVacancies ] );

  return (
    <>
      { !application && (
        <>
          <Header sx={ { boxShadow: 3 } }>
            <div style={ { flex: 1 } } />
            <Engagement sx={ { display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } } }>
              Ready to go?
            </Engagement>
            <ApplyButton sx={ { display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } } } onClick={ () => setApplication( true ) }>
              Send CV
            </ApplyButton>
          </Header>
          <MainSection>
            <div style={ { height: '5rem' } } />
            <FullWidthContainer>
              <FullWidthImage
                alt='tanker ship'
                src='https://www.dnv.com/Images/KL_Tan_291_Deltamarin_tanker_tcm71-198114.jpg'
              />
              <FullWidthCard>
                <Title>
                  The Job of your Dream
                </Title>
                <Description sx={ { maxWidth: '100%' } }>
                  Welcome to Your Maritime Career Hub!
                </Description>
                <Description>
                  Are you a seafarer in search of your next maritime employment opportunity? Look no further –you&apos;ve arrived at the perfect destination. With a proven experience of over 15 years, our company is your trusted partner in connecting maritime professionals with their dream careers. As you navigate through our website, you&apos;ll discover a wide range of positions perfectly corresponding to your skills and aspirations, whether it&apos;s in the world of wet, gas, dry, offshore, or the exciting passenger fleet industry. Your journey begins here.
                </Description>
              </FullWidthCard>
            </FullWidthContainer>
            <SellingContainer>
              <div style={ {} }>
                <Description>
                  Explore Our Available Positions Discover our current job opportunities by clicking on the link below. If you&apos;re interested in a particular department, just select the relevant icon, and you&apos;ll be taken to a customized list of specialized job openings.
                </Description>
                <Description>
                  Can&apos;t Find Your Ideal Position?
                  If you haven&apos;t come across the perfect job yet, don&apos;t worry! You can submit your application through the link, and our dedicated recruiters will work closely with you to identify the perfect match.
                </Description>
                <Description>
                  Find Your Next Career Step
                  Are you ready to take the next step in your career? Start by exploring our available job openings or connect with our committed team to discover the role that best fits you.
                </Description>
                <ApplyButton onClick={ () => window.open( 'https://t.me/Csm_cabin_bot' ) }>
                  Join us on Telegram
                </ApplyButton>
              </div>
              <RepresentationImg
                alt='tanker ship'
                src='https://www.ziprecruiter.com/svc/fotomat/public-ziprecruiter/cms/1011935862PhoneOperator.jpg'
                sx={ { boxShadow: 3 } }
              />
            </SellingContainer>
            <QuoteSection>
              <QuoteContainer>
                <Avatar
                  src='https://images-global.nhst.tech/image/aHRtSi9mREJBSi8yRUNWMmhxU2hNMlZmZjVxK0lQMXVlUEJGUjlUbjk1ST0=/nhst/binary/1ad160b115a666b77162a6d4bb458f62'
                  sx={ { boxShadow: 3 } }
                />
                <Quote>
                  WET
                </Quote>
              </QuoteContainer>
              <QuoteContainer>
                <Avatar
                  src='https://maritimepage.com/wp-content/uploads/2022/09/Ro-ro-vessel-loading-and-discharging-ramp.webp'
                  sx={ { boxShadow: 3 } }
                />
                <Quote>
                  RO-RO
                </Quote>
              </QuoteContainer>
              <QuoteContainer>
                <Avatar
                  src='https://www.czarnikow.com/wp-content/uploads/2020/08/handymax.jpg'
                  sx={ { boxShadow: 3 } }
                />
                <Quote>
                  DRY
                </Quote>
              </QuoteContainer>
              <QuoteContainer>
                <Avatar
                  src='https://www.kongsberg.com/globalassets/maritime/ship.jpg?width=1040&rxy=0.5,0.5'
                  sx={ { boxShadow: 3 } }
                />
                <Quote>
                  PAX
                </Quote>
              </QuoteContainer>
              <QuoteContainer>
                <Avatar
                  src='https://www.marineinsight.com/wp-content/uploads/2022/11/What-Are-Gas-Tanker-Ships.jpg'
                  sx={ { boxShadow: 3 } }
                />
                <Quote>
                  GAS
                </Quote>
              </QuoteContainer>
            </QuoteSection>
            <Table>
              <tbody>
                { !!actualVacancies?.length && !!Object.keys( actualVacancies[ 0 ] )?.length && (
                  actualVacancies.map( ( vacancy: vacancyType ) => (
                    <TableRow key={ vacancy.id }>
                      <TableCell sx={ { textAlign: 'start' } }>
                        { vacancy.title }
                      </TableCell>
                      <TableCell>
                        <ApplyButton onClick={ () => window.open( `vacancy/${ vacancy?.id }`, '_blank' ) }>
                          Details
                        </ApplyButton>
                      </TableCell>
                    </TableRow>
                  ) ) ) }
              </tbody>
            </Table>
            <QuoteSection sx={ { alignItems: 'center' } }>
              <QuoteContainer>
                <Partner
                  src='https://pbs.twimg.com/profile_images/1524296120037527554/zRX0DjZz_400x400.jpg'
                />
              </QuoteContainer>
              <QuoteContainer>
                <Partner
                  src='https://e-nautilia.gr/wp-content/uploads/2020/11/%CE%A3%CF%84%CE%B9%CE%B3%CE%BC%CE%B9%CF%8C%CF%84%CF%85%CF%80%CE%BF-2020-11-05-5.32.55-%CE%BC%CE%BC.png'
                />
              </QuoteContainer>
              <QuoteContainer>
                <Partner
                  src='https://www.doehle.de/wp-content/uploads/2021/03/flag.png'
                />
              </QuoteContainer>
            </QuoteSection>
            <AltFooter>
              <Engagement sx={ { display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } } }>
                Ready to go?
              </Engagement>
              <ApplyButton sx={ { display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } } } onClick={ () => setApplication( true ) } >
                Join Us!
              </ApplyButton>
            </AltFooter>

            <div style={ { height: '5rem' } } />
          </MainSection>
          <Footer sx={ { display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' }, boxShadow: 3 } }>
            <ApplyButton sx={ { width: '94%', marginTop: '1rem', marginLeft: '1rem', height: '3rem' } } onClick={ () => setApplication( true ) }>
              Get a job
            </ApplyButton>
          </Footer>
        </>
      ) }
      { application && (
        <Page>
          <ApplicationHeader onGoBack={ () => setApplication( false ) } />
          <ApplicationMainContainer>
            <Application onClose={ () => setApplication( false ) } />
          </ApplicationMainContainer>
          <ApplicationFooter />
        </Page>
      ) }
    </>
  )
};

export default Landing;
