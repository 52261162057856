import React from 'react';
import { useRouteError } from 'react-router-dom';
import CenteredText from './CenteredText';

export default function ErrorPage() {
  document.title = 'Cloudjob agency | Error 💥';
  const error: any = useRouteError();
  console.error( error );

  return (
    <>
      <CenteredText variant='h3'>
        Oops!
      </CenteredText>
      <CenteredText variant='subtitle1'>
        Sorry, an unexpected error has occurred.
      </CenteredText>
      <CenteredText>
        <CenteredText variant='subtitle2' sx={ { color: 'grey' } }>
          { error?.statusText || error?.message }
        </CenteredText>
      </CenteredText>
    </>
  );
}
