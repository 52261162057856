import React, { useCallback, useEffect } from 'react';
import { Paper, styled } from '@mui/material';
import Menu from './Menu';
import { useContekst } from '../../context';

const StyledBackground = styled( Paper )( {
  margin: -8,
  borderRadius: 0,
  minHeight: '100vh',
  position: 'relative',
} );

const Background: React.FC<{ children: JSX.Element }> = ( { children } ) => {
  const context = useContekst();

  const { pathname } = window.location;

  useEffect( () => {
    if ( pathname && pathname.toLowerCase() !== '' && pathname.toLowerCase() !== '/' && pathname.toLowerCase() !== '/login' ) {
      context.setMenuIsVisible( true );
    } else {
      context.setMenuIsVisible( false );
    }
  }, [ pathname ] );

  return (
    <StyledBackground>
      {/* <BrowserRouter> */ }
      <Menu />
      { children }
      {/* </BrowserRouter> */ }
    </StyledBackground>
  )
};

export default Background;
