import { styled } from '@mui/material';

const ApplicationMainContainer = styled( 'section' )( {
  display: 'flex',
  height: 'max-content',
  padding: '0!important',
  justifyContent: 'center',
  minHeight: 'calc(100vh - 80px)',
  backgroundColor: 'rgb(92, 103, 145)',
} );

export default ApplicationMainContainer;
