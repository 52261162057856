import getErrorMessage from '../helpers/getErrorMessage';
import { useContekst } from '../context/index';

const useFetch = () => {
  const context = useContekst();

  const customeFetch = async ( url: string, method: string, body: {} | undefined, usePreloader = false ) => {
    try {
      if ( usePreloader ) {
        context.setLoading( true );
      }
      const rawData = await fetch( url, {
        body: body ? JSON.stringify( body ) : null,
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': context?.csrf,
        }
      } );
      if ( rawData.status === 200 ) {
        const data = await rawData.json();
        return { status: 'success', data };
      }
      console.error( { response: rawData, url, method, body } );
      throw new Error( `Status ${ rawData?.status } ${ rawData?.statusText }` );
    } catch ( error ) {
      context.setError( getErrorMessage( error ) )
      return { status: 'error', message: error?.message || error }
    } finally {
      if ( usePreloader ) {
        context.setLoading( false );
      }
    }
  };

  return customeFetch;
};

export default useFetch;
