import { styled, Toolbar } from '@mui/material';

const BasicToolbar = styled(Toolbar)({
  gap: 10,
  flexWrap: 'wrap',
  paddingTop: 10,
  paddingBottom: 10,
});

export default BasicToolbar;
