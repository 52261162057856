/* eslint-disable import/no-extraneous-dependencies */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  styled,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useMemo, useState } from 'react';
import { PhoneMask } from '../../helpers/phoneFormatter';
import { formatServerToData } from '../../helpers/dateFormatters';

const StyledDialogContent = styled( DialogContent )( ( { theme } ) => ( {
  display: 'grid',
  paddingTop: 10,
  gap: 10,
  gridTemplateColumns: '1fr 1fr',
  [ theme.breakpoints.down( 'sm' ) ]: {
    gridTemplateColumns: '1fr',
  }
} ) );

const StyledTextField = styled( TextField )( {
  marginTop: 5,
} );

export const departments = [ 'IT', 'Columbia Shipmanagement Ukraine', 'Euro Crew' ]

const AddAndEditModal = ( { open, onClose, toEdit } ) => {
  const [ fName, setFName ] = useState( '' );
  const [ lName, setLName ] = useState( '' );
  const [ bDay, setBDay ] = useState<Date | null | string>( new Date( 1990, 0, 1 ) );
  const [ dept, setDept ] = useState( '' );
  const [ privPhone, setPrivPhone ] = useState<null | string>( '380' );
  const [ privEmail, setPrivEmail ] = useState( '' );
  const [ sex, setSex ] = useState( '' );

  const multipleEdit = useMemo( () => toEdit && toEdit?.length > 1, [ toEdit ] );

  const onCloseModal = () => {
    setFName( '' );
    setLName( '' );
    setBDay( new Date( 1990, 0, 1 ) );
    setDept( '' );
    setPrivPhone( '380' );
    setPrivEmail( '' );
    setSex( '' );
    onClose();
  };

  const onSave = () => { };

  useEffect( () => {
    if ( toEdit?.length === 1 ) {
      const {
        firstName,
        lastName,
        birthDate,
        department,
        personalMobilePhone,
        personalEmail,
        sex: workerSex,
      } = toEdit[ 0 ] || {};
      setFName( firstName || '' );
      setLName( lastName || '' );
      setBDay( formatServerToData( birthDate ) || '' );
      setDept( department );
      setPrivPhone( personalMobilePhone );
      setPrivEmail( personalEmail );
      setSex( workerSex );
    }
  }, [ toEdit ] );

  return (
    <Dialog open={ open } onClose={ onCloseModal }>
      <DialogTitle>{ toEdit ? 'Edit Enployee' : 'Add New Employee' }</DialogTitle>
      <StyledDialogContent>
        <StyledTextField
          disabled={ multipleEdit }
          label='First Name'
          onChange={ event => setFName( event?.target?.value ) }
          value={ fName }
        />
        <StyledTextField
          disabled={ multipleEdit }
          label='Last Name'
          onChange={ event => setLName( event?.target?.value ) }
          value={ lName }
        />
        <DatePicker
          disabled={ multipleEdit }
          sx={ { marginTop: 0.5 } }
          label='Date of birth'
          onChange={ value => setBDay( value ) }
          value={ new Date( bDay ) }
        />
        <StyledTextField
          label='Department'
          onChange={ event => setDept( event?.target?.value ) }
          select
          value={ dept }
        >
          { departments.map( department => (
            <MenuItem key={ department } value={ department }>
              { department }
            </MenuItem>
          ) ) }
        </StyledTextField>
        <PhoneMask
          disabled={ multipleEdit }
          setValue={ setPrivPhone }
          value={ privPhone }
        >
          <StyledTextField disabled={ multipleEdit } />
        </PhoneMask>
        <StyledTextField
          disabled={ multipleEdit }
          label='Personal email'
          onChange={ event => setPrivEmail( event.target.value ) }
          type='email'
          value={ privEmail }
        />
        <StyledTextField
          select
          label='Gender'
          value={ sex }
          onChange={ event => setSex( event?.target?.value ) }
        >
          <MenuItem value='M'>
            Male
          </MenuItem>
          <MenuItem value='F'>
            Female
          </MenuItem>
          <MenuItem value='O'>
            Other
          </MenuItem>
        </StyledTextField>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={ onCloseModal }>Cancel</Button>
        <Button onClick={ onSave }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAndEditModal;
