import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';
import { NavLink } from 'react-router-dom';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  styled,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';

import 'react-phone-number-input/style.css'
import '../../common/App.css'
import PhoneInput from 'react-phone-number-input'
import ApplyButton from '../common/ApplyButton';
import endpoints from "../../requests/endpoints";
import getErrorMessage from '../../helpers/getErrorMessage';

import ApplyButtonContainer from '../common/ApplyButtonContainer';
import { useContekst } from '../../context';
import {
  sailorApplicantType,
  vacancyFormType,
  vacancyType,
} from '../../../types/types';
import Preloader from '../common/Preloader';



const Main = styled( 'div' )( {
  // backgroundColor: 'rgb(222,222,222)',
  maxWidth: 1000,
  width: '100vw',
  height: 'fit-content',
  minHeight: '100%',
} );

const Form = styled( 'form' )( {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
} );

const H1 = styled( 'h1' )( {
  width: '100%',
  textAlign: 'center',
  color: 'rgb(33,45,94)',
  fontFamily: "'Roboto', sans-serif"
} );

const InputContainer = styled( 'label' )( {
  width: '88%',
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
  paddingRight: 20,
  marginTop: '1rem',
  alignItems: 'flex-start',
  justifyContent: 'end',
  fontFamily: "'Roboto', 'sans-serif'",
  color: 'rgb(33,45,94)',
  '&:focus': {
    border: 4,
    borderColor: '#f2b82f'
  },
} );

const FileInput = styled( 'input' )( {
  width: '100%',
  marginLeft: '1rem',
} );

const Input = styled( 'input' )( {
  border: 0,
  height: 50,
  width: '100%',
  borderRadius: 8,
  paddingLeft: 10,
  paddingRight: 10,
} );

type propType = {
  onClose: () => void;
  vacancy?: vacancyType
}

type NationalityType = {
  Id: number,
  Name: string,
  countNat: number,
}

const Application = ( { onClose, vacancy = {} }: propType ) => {
  const context = useContekst();
  const birthDateRef = useRef<HTMLInputElement>();
  const [ agreedWithGDPR, setAgreedWithGDPR ] = useState( true );
  const [ nationalities, setNationalities ] = useState<NationalityType[]>( [] );
  const [ Phone, setPhone ] = useState( '+' );

  const validate = ( body: vacancyFormType ): boolean => {
    const { FirstName, LastName, Nationality, Email, BirthDate } = body;
    if ( !FirstName?.trim() ) {
      context.setError( 'Please enter your first name' );
      return false;
    }
    if ( !LastName?.trim() ) {
      context.setError( 'Please enter your last name' );
      return false;
    }
    if ( !Phone?.trim() || Phone === "+" || Phone.length < 9 ) {
      context.setError( 'Please enter correct phone number in international format' );
      return false;
    }
    if ( !Email?.trim() ) {
      context.setError( 'Please enter your email' );
      return false;
    }
    if ( !BirthDate?.trim() ) {
      context.setError( 'Please enter your birthDate' );
      return false;
    }
    if ( !Nationality ) {
      context.setError( 'Please select your nationality' );
      return false;
    }
    return true;
  };

  const uploadFileToCrm = async ( initialDocId: string, formData: FormData, file ): Promise<boolean> => {
    try {
      formData.delete( 'FirstName' );
      formData.delete( 'LastName' );
      formData.delete( 'Email' );
      formData.delete( 'Phone' );
      formData.delete( 'Rank' );
      formData.delete( 'BirthDate' );
      formData.append( 'file', file );
      formData.append( 'EditedBy', '0' );
      formData.append( 'CreatedBy', '0' );
      formData.append( 'ClientDocsId', initialDocId ); // dict_doc_types - INITIAL
      formData.append( 'seamanData', '{}' );
      formData.append( 'InitialShipTypeGroupId', '1' );

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      const response = await axios.post(
        'https://cloud-crm.in.ua:3001/api/seaman-files/upload-candidate',
        formData,
        config
      );
      return true;
    } catch ( error ) {
      context.setError( getErrorMessage( error ) )
      return false;
    }
  }

  const sendEmailFromCrm = async ( letter ): Promise<boolean> => {
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };

      const response = await axios.post(
        endpoints.crm_emailSend.url,
        JSON.stringify( letter ),
        config
      );
      return true;
    } catch ( error ) {
      context.setError( getErrorMessage( error ) )
      return false;
    }
  }

  const addInitialDocToCrm = async ( applicant: sailorApplicantType ): Promise<number> => {
    try {
      if ( !applicant.Id ) {
        throw new Error( 'No applicantId to add initial doc' );
      }
      const request = await fetch( `https://cloud-crm.in.ua:3001/api/seaman/docs/add/?DocTypeId=229&Place=www_cloudjob_agency&Grade=INITIAL&Number=INITIAL&Comment=added_from_cloudjob_agency&sailorId=${ applicant.Id }&userId=1`, {
        method: 'POST'
      } );
      const initialDocId: number = await request.json();
      if ( !initialDocId ) {
        throw new Error( 'No initial doc id' )
      }
      return initialDocId;

      // const xhrAddDoc = new XMLHttpRequest();
      // xhrAddDoc.open( 'POST', urlAddDoc );
      // xhrAddDoc.onload = async () => {
      //   if ( xhrAddDoc.readyState === 4 && xhrAddDoc.status === 201 || xhrAddDoc.status === 200 ) {
      //     initialDocId = JSON.parse( xhrAddDoc.responseText )

      //     await uploadFileToCrm( initialDocId )
      //     // adding INITIAL doc to just created Applicant
      //     // https://cloud-crm.in.ua:3001/api/seaman/docs/add/?DocTypeId=229&Place=www_cloudjob_agency&Grade=INITIAL&Number=INITIAL&Comment=&sailorId=107502&userId=1
      //   } else {
      //     throw new Error( `Request status: ${ xhrAddDoc.status }` );
      //   }
      // };
      // await xhrAddDoc.send();
    } catch ( error ) {
      context.setError( getErrorMessage( error ) )
      return NaN;
    }
  };

  const onSendApplication = async ( event: any ) => {
    try {

      context.setLoading( true );
      event.preventDefault();
      const data = new FormData( event.target );
      // @ts-ignore
      const formObject: vacancyFormType = Object.fromEntries( data.entries() )
      formObject.BirthDate = birthDateRef.current.value
      const body: vacancyFormType = {
        ...formObject,
        EditedBy: 0,
        CreatedBy: 0,
        VacancyId: Number( vacancy.id )
      };
      const fileBkp = body.file
      delete body.file;
      const isFormValid = validate( body );
      if ( !isFormValid ) return

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const utmSource = urlParams.get('utm_source')
      const utmMedium = urlParams.get('utm_medium') ? ` - ${urlParams.get('utm_medium')}` : ''

      let toSend = JSON.stringify( body );
      const toSendObj = JSON.parse(toSend);
      toSendObj.Source = utmSource + utmMedium
      body.Source = utmSource + utmMedium
      toSend = JSON.stringify( toSendObj );
      const config = {
        headers: { 'Content-Type': 'application/json' },
        params: body
      };
      let candidateId = ''
      const reqCandidateAdd = await axios.post(
        endpoints.crm_addCandidate.url,
        toSend,
        config
      ).then( ( res ) => {
        candidateId = res.data.insertId;
      } ).catch( error => {
        console.error( error );
        return null;
      } );

      if( fileBkp.name === ''){
        if (window.confirm('Do you really want to proceed without attaching your CV file? ' +
            ' It will decrease your chances to apply on this position.' +
            ' Send form anyway without CV ?')) {

          const emailByCompany = vacancy.emailTo.includes('@csm-ua.com') ? 'forcv@csm-ua.com' : 'forcv@eurocrew-ua.com';

          await sendEmailFromCrm( {
            replyTo: 'working_gear@csm-ua.com',
            recipient: `${emailByCompany}, ${vacancy.emailTo}`,
            candidateId,
            vacancy,
            formData: formObject,
          }, )

          context.setSuccess( 'We have received your data and will contact you as soon as possible!' );
          onClose();

        }
      }else{
        const isAdded = await uploadFileToCrm( candidateId, data, fileBkp ).then( ( res ) => {
        } ).catch( error => {
          console.error( "uploadFileToCrm error ", error );
          return null;
        } );

        const emailByCompany = vacancy.emailTo.includes('@csm-ua.com') ? 'forcv@csm-ua.com' : 'forcv@eurocrew-ua.com';

        await sendEmailFromCrm( {
          replyTo: 'working_gear@csm-ua.com',
          recipient: `${emailByCompany}, ${vacancy.emailTo}`,
          candidateId,
          vacancy,
          formData: formObject,
        }, )

        context.setSuccess( 'We have received your data and will contact you as soon as possible!' );
        onClose();
      }



    } catch ( error ) {
      context.setError( getErrorMessage( error ) )
    } finally {
      context.setLoading( false );
    }
  };

  const getNationalities = async () => {
    try {
      const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
      const responseNationalities: { data: NationalityType[] } = await axios.get(
        endpoints.crm_getVacancies.url,
        config,
      );
      setNationalities( responseNationalities.data );
    } catch ( error: unknown ) {
      context.setError( getErrorMessage( error ) )
    }
  };

  useEffect( () => {
    if ( !nationalities.length ) {
      getNationalities()
    }
  }, [ nationalities ] );

  return (
    <>
      <Preloader />
      <Main>
        <Form onSubmit={ ( e ) => onSendApplication( e ) }>
          <H1>Submit your application</H1>
          <InputContainer >
            Résumé/CV
            <FileInput name='file' type='file' />
          </InputContainer>
          <InputContainer >
            First Name (Due to travel passport)
            <Input name='FirstName' type='text' onKeyPress={event => {
              const keyCode = event.keyCode || event.which;
              const keyValue = String.fromCharCode(keyCode);
              if (!/^[a-zA-Z]*$/i.test(keyValue)) {
                event.preventDefault()
                alert('Only Latin characters are allowed')
              }
            }} title='Only Latin characters are allowed' />
          </InputContainer>
          <InputContainer >
            Last Name (Due to travel passport)
            <Input name='LastName' type='text' onKeyPress={event => {
              const keyCode = event.keyCode || event.which;
              const keyValue = String.fromCharCode(keyCode);
              if (!/^[a-zA-Z]*$/i.test(keyValue)) {
                event.preventDefault()
                alert('Only Latin characters are allowed')
              }
            }} title='Only Latin characters are allowed' />
          </InputContainer>
          <InputContainer>
            Nationality
            <Autocomplete
              className='application-selector'
              getOptionLabel={ ( option: NationalityType ) => option.Name || '' }
              multiple={ false }
              options={ nationalities }
              renderInput={ ( params ) => (
                <TextField
                  { ...params }
                  name='Nationality'
                  className='application-selector'
                />
              ) }
            />
          </InputContainer>
          <InputContainer >
            Email
            <Input name='Email' type='email' />
          </InputContainer>
          <InputContainer>
            Phone
            <PhoneInput
                placeholder='Enter phone number'
                name='Phone'
                value={Phone}
                onChange={setPhone}/>
          </InputContainer>
          <InputContainer >
            Last position
            <Input name='Rank' />
          </InputContainer>
          <InputContainer >
            Date of birth
            <DatePicker
              className='application-selector'
              format='dd.MM.yyyy'
              maxDate={ new Date() }
              inputRef={ birthDateRef }
            />
          </InputContainer>
          <ApplyButtonContainer sx={ { mb: -2 } }>
            <NavLink to='/gdpr' target='_blanc'>
              <p style={ { marginTop: 20 } }>I agree with GDPR</p>
            </NavLink>
            <Checkbox checked={ agreedWithGDPR } onChange={ ( _, checked ) => setAgreedWithGDPR( checked ) } />
          </ApplyButtonContainer>
          <ApplyButtonContainer>
            <ApplyButton
              disabled={ !agreedWithGDPR || context.loading }
              type='submit'
            >
              APPLY
            </ApplyButton>
          </ApplyButtonContainer>
        </Form>
      </Main>
    </>
  );
};

Application.defaultProps = {
  vacancy: {}
}

export default Application;
