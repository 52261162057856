/**
 * 
 * @param {Date} filterLocalDateAtMidnight 
 * @param {'yyyy-MM-dd'} cellValue 
 * @returns {filtered array}
 */
export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
  if (cellValue == null) return -1;
  const [year, month, day] = cellValue.split('-');
  const cellDate = new Date(Number(year), Number(month) - 1, Number(day));
  if (filterLocalDateAtMidnight === cellDate) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const trueFalseComparator = () => { };
