/* eslint-disable import/no-extraneous-dependencies */
import { DatePicker } from '@mui/x-date-pickers';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import endpoints from '../../requests/endpoints';
import RichTextEditor from '../common/RichTextEditor';
import useFetch from '../../hooks/useFetch';
import { useContekst } from '../../context';
import {
  addAndEditModalOnCloseType,
  vacancyType,
} from '../../../types/types';
import {
  formatServerToData,
  formatDateToServer,
} from '../../helpers/dateFormatters';

const Fields = styled( 'div' )( ( { theme } ) => ( {
  display: 'grid',
  paddingTop: 10,
  gap: 10,
  marginBottom: 10,
  overflowX: 'hidden',
  gridTemplateColumns: '1fr 1fr',
  [ theme.breakpoints.down( 'sm' ) ]: {
    gridTemplateColumns: '1fr',
  }
} ) );

const StyledTextField = styled( TextField )( {
  marginTop: 5,
} );

type propTypes = {
  isOpen: boolean,
  onClose: ( param: '' | addAndEditModalOnCloseType ) => void,
  toEdit: vacancyType[] | []
}

const AddAndEditModal: React.FC<propTypes> = ( { isOpen, onClose, toEdit } ) => {
  const [ title, setTitle ] = useState<string>( '' );
  const [ vessel, setVessel ] = useState<string>( '' );
  const [ pool, setPool ] = useState<string>( '' );
  const [ rank, setRank ] = useState<string>( '' );
  const [ activeTil, setActiveTil ] = useState<Date | null>( new Date() );
  const [ description, setDescription ] = useState<string>( '' );
  const [ totalVacancies, setTotalVacancies ] = useState<number>( 1 );
  const [ vesselType, setVesselType ] = useState<string>( '' );
  const [ editorIsFocused, setEditorIsFocused ] = useState<boolean>( false );
  const context = useContekst();
  const fetch = useFetch();

  const multipleEdit: boolean = useMemo( () => toEdit && toEdit?.length > 1, [ toEdit ] );

  const onCloseModal = ( param?: addAndEditModalOnCloseType ) => {
    setActiveTil( new Date() );
    setDescription( '' );
    setPool( '' );
    setRank( '' );
    setTitle( '' );
    setTotalVacancies( 1 );
    setVessel( '' );
    onClose( param );
  };

  const onSave = async () => {
    const body: vacancyType = {
      applicants: totalVacancies,
      createdBy: 'Admin',
      closedAt: formatDateToServer( activeTil ),
      description,
      isActive: true,
      isApproved: false,
      isClosed: false,
      isDeleted: false,
      pool,
      rank,
      title,
    };
    if ( vessel ) {
      body.vessel = vessel;
    }
    if ( vesselType ) {
      body.vesselType = vesselType;
    }
    if ( toEdit ) {
      body.id = toEdit.map( ( vac: vacancyType ) => vac.id ).join();
      delete body.createdBy
      body.editedBy = 'Admin'
      const response = await fetch( endpoints.vacancyEdit.url, endpoints.vacancyEdit.method, body, true );
      if ( response.status === 'success' ) {
        onClose( { updatedVacancy: response.data.updatedVacancy, type: 'edit' } );
        context.setSuccess( 'The vacancy was successfully updated' );
      }
    } else {
      const response = await fetch( endpoints.vacancyAdd.url, endpoints.vacancyAdd.method, body, true );
      if ( response.status === 'success' ) {
        onClose( { newVacancy: response.data.newVacancy, type: 'add' } );
        context.setSuccess( 'The vacancy was successfully created' );
      }
    }
  };

  useEffect( () => {
    if ( toEdit?.length === 1 ) {
      const {
        title: propsTitle,
        applicants: propsAplicants,
        rank: propsRank,
        pool: propsPool,
        vessel: propsVessel,
        description: propsDescription,
        closedAt: propsClosedAt,
      } = toEdit[ 0 ] || {};
      setTitle( propsTitle || '' );
      setVessel( propsVessel || '' );
      setActiveTil( formatServerToData( propsClosedAt ) || '' );
      setDescription( propsDescription || '' );
      setTotalVacancies( propsAplicants || 1 );
      setPool( propsPool || '' );
      setRank( propsRank || '' );
    }
  }, [ toEdit ] );

  return (
    <Dialog open={ isOpen } onClose={ onCloseModal } fullScreen={ window.innerWidth < 540 }>
      <DialogTitle>
        { toEdit ? 'Edit Vacancy' : 'Add New Vacancy' }
      </DialogTitle>
      <DialogContent sx={ { overflowX: 'hidden' } }>
        <Fields>
          <StyledTextField
            disabled={ multipleEdit }
            label='Title'
            onChange={ event => setTitle( event?.target?.value ) }
            value={ title }
          />
          <Tooltip
            title={
              <div style={ { whiteSpace: 'pre-line' } }>
                { !vesselType && vessel && `Name: ;\nOwner: ;\nFlag: ;\nDwt: ;\nType: ;\nYear: ;\nEngine Model: ;` }
                { !vesselType && !vessel && 'Please, select desired vessel' }
                { !!vesselType && 'To add a particular vessel you have to remove vessel type' }
              </div>
            }>
            <StyledTextField
              disabled={ !!vesselType }
              label='Vessel'
              onChange={ event => setVessel( event?.target?.value ) }
              value={ vessel }
            />
          </Tooltip>
          <DatePicker
            disabled={ multipleEdit }
            sx={ { marginTop: 0.5 } }
            label='Active til'
            onChange={ value => setActiveTil( value ) }
            value={ new Date( activeTil ) }
          />
          <Tooltip
            title={ vessel ? 'To add a vessel type you have to remove vessel first' : '' }>
            <StyledTextField
              disabled={ !!vessel }
              label='Vessel Type'
              onChange={ event => setVesselType( event?.target?.value ) }
              value={ vesselType }
            />
          </Tooltip>
          <StyledTextField
            label='Pool'
            onChange={ event => setPool( event?.target?.value ) }
            value={ pool }
          />
          <StyledTextField
            label='Rank'
            onChange={ event => setRank( event?.target?.value ) }
            value={ rank }
          />
          <StyledTextField
            disabled={ multipleEdit }
            type='number'
            label='Total Vacancies'
            onChange={ event => setTotalVacancies( parseInt( event?.target?.value, 10 ) ) }
            value={ totalVacancies }
          />
        </Fields>
        <RichTextEditor
          disabled={ multipleEdit }
          setValue={ setDescription }
          value={ description }
          onFocus={ () => setEditorIsFocused( true ) }
          onBlur={ () => setEditorIsFocused( false ) }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => onCloseModal() }>
          Cancel
        </Button>
        <Button
          disabled={ editorIsFocused }
          onClick={ onSave }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo( AddAndEditModal );
