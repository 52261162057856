import { IconButton, Tooltip, useColorScheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { WbSunny, Nightlight } from '@mui/icons-material';

const ModeSwitch: React.FC = () => {
  const getCurrentTheme = () => window.matchMedia( '(prefers-color-scheme: dark)' ).matches;
  const { mode, setMode } = useColorScheme();
  const [ mounted, setMounted ] = useState( false );

  useEffect( () => {
    setMounted( true );
    if ( getCurrentTheme() ) {
      setMode( 'dark' );
    } else {
      setMode( 'light' )
    }
  }, [] );

  if ( !mounted ) {
    // for server-side rendering
    // learn more at https://github.com/pacocoursey/next-themes#avoid-hydration-mismatch
    return null;
  }

  return (
    <Tooltip title='Change mode'>
      <IconButton
        onClick={ () => {
          if ( mode === 'light' ) {
            setMode( 'dark' );
          } else {
            setMode( 'light' );
          }
        } }
      >
        { mode === 'light' ? <Nightlight /> : <WbSunny /> }
      </IconButton>
    </Tooltip>
  );
};

export default ModeSwitch;
