import { styled } from '@mui/material';

const ApplyButtonContainer = styled( 'div' )( {
  width: 800,
  maxWidth: '90vw',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  marginTop: '1rem',
  marginBottom: '1rem',
  justifyContent: 'end',
  alignItems: 'center'
} );

export default ApplyButtonContainer;