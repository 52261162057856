import {
  AccountCircle,
  BorderColor,
  Dashboard,
  Menu as MenuIcon,
  Search,
  Settings,
  Visibility,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  styled,
  TextField,
  Toolbar,
  Tooltip,
  useColorScheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ModeSwitch from './ModeSwitch';
import MenuDropDown from './MenuDropDown'
import { useContekst } from '../../context';

const StyledToolBar = styled( Toolbar )( {
  justifyContent: 'space-between',
} );

const StyledTextInput = styled( TextField )( {
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      color: 'white',
    },
  },
} );

const Menu: React.FC = () => {
  const context = useContekst();
  const { mode } = useColorScheme();
  const [ anchor, setAnchor ] = useState<HTMLElement | null>( null );
  const navigate = useNavigate();

  const getColor = ( isActive: boolean, isMenu: boolean ) => {
    if ( isActive ) {
      if ( isMenu ) {
        return 'rgb(66, 165, 245)';
      }
      return mode === 'light' ? 'white' : 'rgb(66, 165, 245)'
    }
    if ( isMenu ) {
      return 'rgb(117,117,117)';
    }
    return mode === 'light' ? 'rgb(25,54,93)' : '#fff'
  };

  if ( context?.menuIsVisible ) {
    return (
      <>
        <Box>
          <AppBar position='static' color='primary' >
            <StyledToolBar >
              <Tooltip title='Dashboard' sx={ { display: { s: 'none', xs: 'block', md: 'none', lg: 'none' } } }>
                <IconButton
                  color='inherit'
                  onClick={ ( event: React.MouseEvent<HTMLElement> ) => setAnchor( event.currentTarget ) }
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
              <Box sx={ { display: { s: 'block', xs: 'none', md: 'block', lg: 'block' } } }>
                <NavLink
                  to='/dashboard'
                  style={ ( { isActive } ) => ( {
                    color: getColor( isActive, false ),
                  } )
                  }
                >
                  <Tooltip title='Dashboard'>
                    <IconButton
                      color='inherit'
                      onClick={ () => navigate( '/dashboard' ) }
                    >
                      <Dashboard />
                    </IconButton>
                  </Tooltip>
                </NavLink>
                <NavLink
                  to='/viewer'
                  style={ ( { isActive } ) => ( {
                    color: getColor( isActive, false ),
                  } )
                  }
                >
                  <Tooltip title='Viewer'>
                    <IconButton
                      color='inherit'
                      onClick={ () => navigate( '/viewer' ) }
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </NavLink>
                <NavLink
                  to='/vacancies'
                  style={ ( { isActive } ) => ( {
                    color: getColor( isActive, false ),
                  } )
                  }
                >
                  <Tooltip title='Vacancies'>
                    <IconButton
                      color='inherit'
                      onClick={ () => navigate( '/vacancies' ) }
                    >
                      <BorderColor />
                    </IconButton>
                  </Tooltip>
                </NavLink>
              </Box>
              <Tooltip title='Search by anything'>
                <StyledTextInput
                  sx={ { input: { color: 'white' } } }
                  size='small'
                  InputProps={ {
                    endAdornment: (
                      <IconButton size='small'>
                        <Search />
                      </IconButton>
                    )
                  } }
                />
              </Tooltip>
              <Box sx={ { display: { s: 'block', xs: 'none', md: 'block', lg: 'block' } } }>
                <NavLink
                  to='/settings'
                  style={ ( { isActive } ) => ( {
                    color: getColor( isActive, false ),
                  } )
                  }
                >
                  <Tooltip title='Settings'>
                    <IconButton
                      color='inherit'
                      onClick={ () => navigate( '/settings' ) }
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                </NavLink>
                <ModeSwitch />
                <Tooltip title='Your Profile'>
                  <IconButton onClick={ ( event: React.MouseEvent<HTMLElement> ) => setAnchor( event.currentTarget ) }>
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
              </Box>
            </StyledToolBar>
          </AppBar>
        </Box>
        <MenuDropDown anchor={ anchor } setAnchor={ setAnchor } />
      </>
    );
  }
  return null;
};

export default Menu;
