/* eslint-disable import/no-extraneous-dependencies */
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultColDef, viewerColumnDefs as columnDefs } from '../../helpers/agGridColumnDefs';
import BasicLayout from '../common/BasicLayout';
import BasicLayoutGridContainer from '../common/BasicLayoutGridContainer'
import { onGridReadyType } from '../../../types/types'
import Toolbar from './ToolBar';
import LoggedInBoundry from '../common/LoggedInBoundry';

export const rowData = [
  {
    id: 1,
    avatar: 'https://scontent-kul2-1.xx.fbcdn.net/v/t39.30808-6/289548452_2621369227998952_4925608878201381001_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=X2yYR3fHM7IAX_kjpNw&_nc_ht=scontent-kul2-1.xx&oh=00_AfDgEm7kz80ndzqeRwP9tYgG-N2uNmUs8UJNwRN9UrabNg&oe=64910B4D',
    firstName: 'Maksym',
    lastName: 'Bahrov',
    birthDate: '1990-12-16',
    department: 'IT',
    isInUkraine: 0,
    personalMobilePhone: '+380989766903',
    personalEmail: 'm.bahrov@gmail.com',
    sex: 'M',
    tags: [ '#frontend', '#croatia' ]
  },
  {
    id: 2,
    avatar: 'https://scontent-kul2-1.xx.fbcdn.net/v/t39.30808-6/344041897_542911888014815_770254835804271024_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=9w4RVXycQEAAX8SVM-5&_nc_ht=scontent-kul2-1.xx&oh=00_AfCVk936BMATtIZryb-dAGzuKUEfU4Z6r6mlKz368WOK0A&oe=649154A3',
    firstName: 'Oleksandr',
    lastName: 'Moldovanov',
    birthDate: '1986-04-01',
    department: 'IT',
    isInUkraine: 1,
    personalMobilePhone: '+380937952414',
    personalEmail: 'working_gear@csm-ua.com',
    sex: 'M',
    tags: [ '#backend' ]
  },
  {
    id: 3,
    avatar: 'https://scontent-kul2-1.xx.fbcdn.net/v/t39.30808-6/347103559_2241144066275784_8942945388420896431_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=OmDSmo05O9IAX9rxsJu&_nc_ht=scontent-kul2-1.xx&oh=00_AfBVNqgrelTioKPeTQG_xDWmLT3fxzrXA3--PmLJS3ZEvg&oe=64906C8B',
    firstName: 'Dmytro',
    lastName: 'Dudnyk',
    birthDate: '1979-01-03',
    department: 'IT',
    isInUkraine: 1,
    personalMobilePhone: '+380939601598',
    personalEmail: null,
    sex: 'M',
    tags: [],
  },
  {
    id: 4,
    avatar: 'https://scontent-kul2-1.xx.fbcdn.net/v/t39.30808-6/348585765_791279226001178_8890455376514580858_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=ZfqY6ieJZsIAX_59-0t&_nc_ht=scontent-kul2-1.xx&oh=00_AfD2DxTZbANH2VJNoBJWJWe3OpZUY1LBQCm1Sc4paCSIyg&oe=6491217B',
    firstName: 'Oleksii',
    lastName: 'Borovykov',
    birthDate: '1992-04-11',
    department: 'Euro Crew',
    isInUkraine: 1,
    personalMobilePhone: '+380636693705',
    personalEmail: null,
    sex: 'M',
    tags: [],
  },
  {
    id: 5,
    avatar: 'https://scontent-kul3-1.xx.fbcdn.net/v/t1.6435-9/48356041_2383741388305988_5147128467254411264_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=174925&_nc_ohc=QcNwjc-wZDMAX9CN5WS&_nc_ht=scontent-kul3-1.xx&oh=00_AfDdCqt2CdmFAniVriB4QTPVA6ZmelvBl2z_1xHvY1WTOg&oe=64B3B2F1',
    firstName: 'Hanna',
    lastName: 'Sokolova',
    birthDate: '1980-12-24',
    department: 'Columbia Shipmanagement Ukraine',
    personalMobilePhone: '+380504955420',
    personalEmail: null,
    isInUkraine: 1,
    sex: 'F',
    tags: [],
  },
];

const Viewer = () => {
  document.title = 'Cloudjob agency | Viewer 👁️';
  const [ modal, setModal ] = useState<string | null>( '' );
  const [ gridApi, setGridApi ] = useState<null | onGridReadyType>( null );
  const [ selectedRows, setSelectedRows ] = useState<null | [ {} ]>( null );
  const navigate = useNavigate();

  const openWorker = ( value: { data: { id: number } } ) => {
    navigate( `/profile/ ${ value?.data?.id }` );
  };

  const onGridReady = useCallback( ( { api, columnApi }: onGridReadyType ) => {
    setGridApi( { api, columnApi } );
  }, [] );

  const onRowClicked = useCallback( () => {
    setSelectedRows( gridApi?.api?.getSelectedRows() );
  }, [ gridApi ] );

  return (
    <LoggedInBoundry>
      <BasicLayout>
        <Toolbar
          modal={ modal }
          selectedRows={ selectedRows }
          setModal={ setModal }
        />
        <BasicLayoutGridContainer>
          <AgGridReact
            columnDefs={ columnDefs }
            defaultColDef={ defaultColDef }
            onGridReady={ onGridReady }
            onCellDoubleClicked={ openWorker }
            onRowClicked={ onRowClicked }
            rowData={ rowData }
            rowSelection='multiple'
          />
        </BasicLayoutGridContainer>
      </BasicLayout>
    </LoggedInBoundry>
  );
};

export default Viewer;
