import React, { useEffect, useState } from 'react';

// Define the type for the duplicates array
type DuplicateItem = {
  Id: string; // Unique identifier
  LastName: string;
  FirstName: string;
  BirthDate: string;
};

const Proxy: React.FC = () => {
  const [duplicates, setDuplicates] = useState<DuplicateItem[] | null>(null);

  useEffect(() => {
    document.title = 'Cloudjob proxy component';

    // Fetch data
    const fetchData = async () => {
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const birthDate = urlParams.get('birthDate');
        const lastName = urlParams.get('lastName');
        const firstName = urlParams.get('firstName');

        if(!lastName || !birthDate){
          setDuplicates([]);
          return;
        }

        const response = await fetch(
            `https://cloud-crm.in.ua:3001/api/seaman/get-duplicates?LastName=${lastName}&FirstName=${firstName}&BirthDate=${birthDate}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                LastName: lastName,
                FirstName: firstName,
                BirthDate: birthDate,
              }),
            }
        );

        if (response.ok) {
          const data = await response.json();
          setDuplicates(data);
        } else {
          console.error('Error fetching data:', response);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchData();
  }, []);

  return (
      <div className='proxy-body'>
        {/* Render the duplicates */}
        {duplicates && duplicates.length > 0 ? (
            <ul className='duplicates-list'>
              {duplicates.map((item) => (
                  <li key={item.Id} className='duplicate-item'>
                    {/* Display relevant properties from the item */}
                    <span className='last-name'>{item.LastName}</span>,{' '}
                    <span className='first-name'>{item.FirstName}</span>,{' '}
                    <span className='birth-date'>{item.BirthDate}</span>
                  </li>
              ))}
            </ul>
        ) : (
            <p>No duplicates found.</p>
        )}
        {/* Rest of your component */}
      </div>
  );
};

export default Proxy;
