import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Dashboard from './components/dashboard/index.tsx';
import ErrorPage from './components/common/ErrorPage.tsx';
import Landing from './components/landing/Landing.tsx'
import Login from './components/login/index.tsx';
import Settings from './components/settings/index.tsx';
import Vacancies from './components/vacancies/index.tsx';
import Viewer from './components/viewer/index.tsx';
import WellcomePage from './components/login/WellcomePage.tsx';
import Background from './components/common/Background.tsx';
import Profile from './components/profile/index.tsx';
import Vacancy from './components/vacancies/Vacancy.tsx';
import Gdpr from './components/common/Gdpr.tsx'
import Proxy from './components/common/Proxy'

export default createBrowserRouter([
  {
    path: '/',
    element: (
      <Background>
        <WellcomePage />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: (
      <Background>
        <Login />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard',
    element: (
      <Background>
        <Dashboard />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/viewer',
    element: (
      <Background>
        <Viewer />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/settings',
    element: (
      <Background>
        <Settings />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/vacancies',
    element: (
      <Background>
        <Vacancies />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile/:id?',
    element: (
      <Background>
        <Profile />
      </Background>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/vacancy/:id',
    element: <Vacancy />,
  },
  {
    path: '/landing',
    element: <Landing />,
  },
  {
    path: '/gdpr',
    element: <Gdpr />,
  },
  {
    path: '/proxy',
    element: <Proxy />,
  },
]);
