import {
  ActiveFormatter,
  ageFormatter,
  CloseFormatter,
  dateFormatter,
  emailFormatter,
  genderFormatter,
  phoneFormatter,
  trueFalseFormatter,
} from './agGridFormatters';
import { dateComparator } from './agGridComparators';
import { vacancyType } from '../../types/types'

export const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
};

export const viewerColumnDefs = [
  {
    field: 'firstName',
    headerName: 'First Name',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
  },
  {
    field: 'personalMobilePhone',
    headerName: 'Personal Phone',
    cellRenderer: (param: any) => phoneFormatter(param?.data?.personalMobilePhone),
    width: 180,
    flex: 0,
  },
  {
    field: 'personalEmail',
    headerName: 'Personal Email',
    cellRenderer: (param: any) => emailFormatter(param?.data?.personalEmail),
  },
  {
    field: 'birthDate',
    headerName: 'Birth Date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
    },
    valueFormatter: dateFormatter,
    width: 120,
    flex: 0,
  },
  {
    field: 'birthDate',
    headerName: 'Age',
    filter: 'agNumberColumnFilter',
    width: 80,
    flex: 0,
    valueGetter: ageFormatter,
  },
  {
    field: 'department',
    headerName: 'Department',
  },
  {
    field: 'isInUkraine',
    headerName: 'Is In Ukraine',
    cellRenderer: param => trueFalseFormatter(param?.data?.isInUkraine),
    filter: false,
    width: 100,
    flex: 0,
  },
  {
    field: 'sex',
    headerName: 'Gender',
    cellRenderer: param => genderFormatter(param?.data?.sex),
    filter: false,
    width: 90,
    flex: 0,
  },
];

export const vacanciesColumnDefs = [
  {
    field: 'isActive',
    headerName: 'Active',
    cellRenderer: (param: {data: vacancyType}) => ActiveFormatter(param?.data),
    filter: false,
    width: 100,
    flex: 0,
  },
  {
    field: 'vessel',
    headerName: 'Vessel',
    width: 130,
    flex: 0,
  },
  {
    field: 'rank',
    headerName: 'Rank',
    width: 130,
    flex: 0,
  },
  {
    field: 'pool',
    headerName: 'Pool',
    width: 130,
    flex: 0,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
    },
    valueFormatter: dateFormatter,
    width: 120,
    flex: 0,
  },
  {
    field: 'closedAt',
    headerName: 'Сlosed',
    cellRenderer: (param: {data: vacancyType}) => CloseFormatter(param?.data),
    filter: false,
    width: 100,
    flex: 0,
  },
  {
    field: 'applicants',
    headerName: 'Applicants',
    width: 130,
    flex: 0,
  },
  {
    field: 'approved',
    headerName: 'Approved',
    width: 130,
    flex: 0,
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    width: 130,
    flex: 0,
  },
  {
    field: 'vesselName',
    headerName: 'Vessel name',
    width: 140,
    flex: 0,
  },
  {
    field: 'vesselOwner',
    headerName: 'Vessel owner',
    width: 150,
    flex: 0,
  },
  {
    field: 'vesselFlag',
    headerName: 'Vessel flag',
    width: 130,
    flex: 0,
  },
  {
    field: 'dwt',
    headerName: 'Dead weight',
    width: 140,
    flex: 0,
  },
  {
    field: 'vesselType',
    headerName: 'Vessel type',
    width: 130,
    flex: 0,
  },
  {
    field: 'vesselBuildYear',
    headerName: 'Vessel build year',
    width: 170,
    flex: 0,
  },
  {
    field: 'vesselEngineModel',
    headerName: 'Vessel engine model',
    width: 190,
    flex: 0,
  },
];
