import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

type ContextType = {
  csrf: string,
  setCsrf: Dispatch<React.SetStateAction<string>>,
  error: string,
  setError: Dispatch<React.SetStateAction<string>>,
  loading: boolean,
  setLoading: Dispatch<React.SetStateAction<boolean>>,
  menuIsVisible: boolean,
  setMenuIsVisible: Dispatch<React.SetStateAction<boolean>>,
  success: string | null,
  setSuccess: Dispatch<React.SetStateAction<string | null>>
}

const Context = createContext<ContextType>( {
  csrf: '',
  setCsrf: () => { },
  error: '',
  setError: () => { },
  loading: false,
  setLoading: () => { },
  menuIsVisible: false,
  setMenuIsVisible: () => { },
  success: null,
  setSuccess: () => { },
} );

export const useContekst = () => useContext( Context );

const ContextProvider: React.FC<PropsWithChildren> = ( { children } ) => {
  const [ csrf, setCsrf ] = useState<string>( '' );
  const [ error, setError ] = useState<string>( '' );
  const [ loading, setLoading ] = useState<boolean>( false );
  const [ menuIsVisible, setMenuIsVisible ] = useState<boolean>( false );
  const [ success, setSuccess ] = useState<string | null>( null );

  const value = useMemo( () => ( {
    csrf,
    setCsrf,
    error,
    setError,
    loading,
    setLoading,
    menuIsVisible,
    setMenuIsVisible,
    success,
    setSuccess,
  } ), [ csrf, error, loading, menuIsVisible, success ] );

  return (
    <Context.Provider value={ value }>
      { children }
    </Context.Provider>
  );
};

export default ContextProvider;
