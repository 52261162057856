import {
  Add as AddIcon,
  BorderColor,
  Clear,
  Search as SearchIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import AddAndEditModal from './AddAndEditModal';
import BasicToolbar from '../common/BasicToolbar'
import DeleteModal from './DeleteModal';
import { addAndEditModalOnCloseType, vacancyType } from '../../../types/types';

type propsTypes = {
  modal: string,
  onAddNewVacancy: ( vacancy: vacancyType ) => void,
  onRemoveCurrentVacancy: () => void,
  onUpdateVacancy: ( vacancy: vacancyType ) => void,
  selectedRows: null | [] | vacancyType[],
  setModal: ( string: string ) => void,
}

const Toolbar: React.FC<propsTypes> = ( {
  modal,
  onAddNewVacancy,
  onRemoveCurrentVacancy,
  onUpdateVacancy,
  selectedRows,
  setModal,
} ) => {
  const onOpen = ( param: string ) => {
    // eslint-disable-next-line no-restricted-globals
    setModal( param );
  };

  const onaddAndEditClose = ( param?: addAndEditModalOnCloseType ) => {
    if ( param?.type === 'add' ) {
      onAddNewVacancy( param?.newVacancy );
    } else if ( param?.type === 'edit' ) {
      onUpdateVacancy( param?.updatedVacancy );
    }
    onOpen( '' );
  };

  const onRemoveClose = ( parameter?: 'success' ) => {
    if ( parameter ) {
      onRemoveCurrentVacancy();
    }
    onOpen( '' );
  };

  return (
    <>
      <BasicToolbar>
        <Button
          size='large'
          startIcon={ <SearchIcon /> }
          variant='contained'
          color='primary'
          onClick={ () => onOpen( 'search' ) }
        >
          Search
        </Button>
        <Button
          size='large'
          startIcon={ <AddIcon /> }
          variant='contained'
          color='primary'
          onClick={ () => onOpen( 'add' ) }
        >
          Add
        </Button>
        <Button
          disabled={ !selectedRows?.length }
          color='primary'
          onClick={ () => onOpen( 'edit' ) }
          size='large'
          startIcon={ <BorderColor /> }
          variant='contained'
        >
          Edit
        </Button>
        <Button
          disabled={ !selectedRows?.length }
          onClick={ () => onOpen( 'delete' ) }
          size='large'
          startIcon={ <Clear /> }
          variant='contained'
          color='primary'
        >
          Remove
        </Button>
      </BasicToolbar>
      <AddAndEditModal
        isOpen={ modal === 'add' || modal === 'edit' }
        onClose={ onaddAndEditClose }
        toEdit={ modal === 'edit' ? selectedRows : null }
      />
      <DeleteModal
        isOpen={ modal === 'delete' }
        onClose={ onRemoveClose }
        selectedRows={ selectedRows }
      />
    </>
  )
};

export default Toolbar;