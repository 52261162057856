import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, MenuItem, TextField } from '@mui/material';
import { Close, Upload } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { Link, useParams } from 'react-router-dom';
import ProfileLayout from '../common/ProfileLayout'
import { rowData } from '../viewer';
import { departments } from '../viewer/AddAndEditModal';
import { formatServerToData, getAgeFromDate } from '../../helpers/dateFormatters'
import { PhoneMask } from '../../helpers/phoneFormatter';
import LoggedInBoundry from '../common/LoggedInBoundry';

const Profile = () => {
  const [ firstName, setFirstName ] = useState<string>( '' );
  const [ lastName, setLastName ] = useState<string>( '' );
  const [ birthDate, setBirthDate ] = useState<null | Date>( null );
  const [ department, setDepartment ] = useState<string>( '' );
  const [ personalMobilePhone, setPersonalMobilePhone ] = useState<string>( '' );
  const [ personalEmail, setPersonalEmail ] = useState<string>( '' );
  const [ sex, setSex ] = useState<string>( '' );
  const [ avatar, setAvatar ] = useState( '' );
  const [ tags, setTags ] = useState<null | string[]>( [] );
  const params = useParams();

  const getUserdata = useCallback( () => {
    const { id = 1 } = params;
    const worker = rowData.find( person => person.id === +id )
    setFirstName( worker?.firstName );
    setLastName( worker?.lastName );
    setBirthDate( formatServerToData( worker?.birthDate ) || '' );
    setDepartment( worker?.department );
    setPersonalMobilePhone( worker?.personalMobilePhone );
    setPersonalEmail( worker?.personalEmail );
    setSex( worker?.sex );
    setAvatar( worker?.avatar );
    setTags( worker?.tags );
  }, [] );

  useEffect( () => {
    document.title = 'Cloudjob agency | My Profile 💁🏻';
    getUserdata();
  }, [ getUserdata ] );

  return (
    <LoggedInBoundry>
      <ProfileLayout sx={ { gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' } } }>
        <div style={ { gridColumn: '1 / -1', justifySelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
          <img
            alt='User'
            height='150'
            width='auto'
            src={ avatar }
          />
          <div>
            <IconButton>
              <Upload />
            </IconButton>
            <IconButton>
              <Close />
            </IconButton>
          </div>
        </div>
        <TextField
          label='First Name'
          value={ firstName || '' }
          onChange={ event => setFirstName( event?.target?.value ) }
        />
        <TextField
          label='First Name'
          value={ lastName || '' }
          onChange={ event => setLastName( event?.target?.value ) }
        />
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <DatePicker
            label='Date of birth'
            onChange={ value => setBirthDate( value ) }
            value={ new Date( birthDate ) }
            sx={ { width: 'calc(50% - 5px)', marginRight: 1 } }
          />
          <TextField
            disabled
            label='Age'
            sx={ { width: 'calc(50% - 5px)' } }
            value={ getAgeFromDate( birthDate ) }
          />
        </div>
        <TextField
          label='Department'
          onChange={ event => setDepartment( event?.target?.value ) }
          select
          value={ department || '' }
        >
          { departments.map( dept => (
            <MenuItem key={ dept } value={ dept }>
              { dept }
            </MenuItem>
          ) ) }
        </TextField>
        <PhoneMask
          setValue={ setPersonalMobilePhone }
          value={ personalMobilePhone || '' }
        >
          <TextField />
        </PhoneMask>
        <TextField
          label='Personal email'
          onChange={ event => setPersonalEmail( event.target.value ) }
          type='email'
          value={ personalEmail || '' }
        />
        <TextField
          select
          label='Gender'
          value={ sex || '' }
          onChange={ event => setSex( event?.target?.value ) }
        >
          <MenuItem value='M'>
            Male
          </MenuItem>
          <MenuItem value='F'>
            Female
          </MenuItem>
          <MenuItem value='O'>
            Other
          </MenuItem>
        </TextField>
        <div style={ { gridColumn: '1 / -1' } } >
          { !!tags?.length && tags.map( tag => (
            <Link key={ tag } style={ { marginRight: 5 } } href='/profile'>{ tag }</Link>
          ) ) }
        </div>
      </ProfileLayout >
    </LoggedInBoundry>
  );
};

export default Profile;
