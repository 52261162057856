/* eslint-disable import/no-extraneous-dependencies */
import { AgGridReact } from 'ag-grid-react';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import BasicLayout from '../common/BasicLayout';
import BasicLayoutGridContainer from '../common/BasicLayoutGridContainer';
import { defaultColDef, vacanciesColumnDefs } from '../../helpers/agGridColumnDefs';
import { onGridReadyType, vacancyType } from '../../../types/types'
import Toolbar from './ToolBar';
import LoggedInBoundry from '../common/LoggedInBoundry';
import useFetch from '../../hooks/useFetch';
import endpoints from '../../requests/endpoints';

const Vacancies: React.FC = () => {
  document.title = 'Cloudjob agency | Vacancies 🧑🏻‍💼';
  const [ gridApi, setGridApi ] = useState<null | onGridReadyType>( null );
  const [ modal, setModal ] = useState<string>( '' );
  const [ selectedRows, setSelectedRows ] = useState<[] | vacancyType[]>( [] );
  const [ vacanciesList, setVacanciesList ] = useState<[] | vacancyType[]>( [] );

  const fetch = useFetch();

  const onGridReady = useCallback( ( { api, columnApi }: onGridReadyType ) => {
    setGridApi( { api, columnApi } );
  }, [] );

  const onRowClicked = useCallback( () => {
    setSelectedRows( gridApi?.api?.getSelectedRows() );
  }, [ gridApi ] );

  const openVacancy = ( vacancy: { data: { id: string } } ) => {
    window.open( `vacancy/${ vacancy?.data?.id }`, '_blank' )
  };

  const getVacancies = async () => {
    const res = await fetch( endpoints.vacancyList.url, endpoints.vacancyList.method, undefined, true );
    if ( res.status === 'success' ) {
      setVacanciesList( res.data.vacancies );
    }
  };

  const onAddVacancy = ( vac: vacancyType ) => {
    const newVacancies = [ vac, ...vacanciesList ];
    setVacanciesList( newVacancies );
  };

  const onUpdateVacancy = ( vac: vacancyType ) => {
    // TODO: handle that when API is ready
  };

  const onRemoveCurrentVacancy = () => {
    const selectedRow = gridApi.api.getSelectedRows();
    gridApi.api.applyTransaction( { remove: selectedRow } );
  };

  useEffect( () => {
    getVacancies();
  }, [] );

  return (
    <LoggedInBoundry>
      <BasicLayout>
        <Toolbar
          modal={ modal }
          selectedRows={ selectedRows }
          setModal={ setModal }
          onRemoveCurrentVacancy={ onRemoveCurrentVacancy }
          onAddNewVacancy={ onAddVacancy }
          onUpdateVacancy={ onUpdateVacancy }
        />
        <BasicLayoutGridContainer>
          <AgGridReact
            columnDefs={ vacanciesColumnDefs }
            defaultColDef={ defaultColDef }
            onGridReady={ onGridReady }
            onRowClicked={ onRowClicked }
            onRowDoubleClicked={ ( val: any ) => openVacancy( val ) }
            rowData={ vacanciesList }
            rowSelection='multiple'
          />
        </BasicLayoutGridContainer>
      </BasicLayout>
    </LoggedInBoundry>
  );
};

export default Vacancies;
