import React from 'react';
import CenteredText from '../common/CenteredText'
import LoggedInBoundry from '../common/LoggedInBoundry';

const Settings = () => {
  document.title = 'Cloudjob agency | Settings ⚙️';
  return (
    <LoggedInBoundry>
      <CenteredText variant='h3'>
        Settings is under maintanence
      </CenteredText>
    </LoggedInBoundry>
  );
};

export default Settings;
