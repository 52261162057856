import { DeleteForever } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

const DeleteModal = ({ onClose, open, selectedRows }) => {

  const onRemove = () => {
    console.log(selectedRows);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete</DialogTitle>
      <DialogContent>
        <Typography>
          Do you really want to remove selected eployees?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onRemove}>
          <DeleteForever /> Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
