const host = process.env.REACT_APP_HOST
const protocol = process.env.REACT_APP_PROTOCOL

export default {
  login: {
    url: `${protocol}${host}:3001/login`,
    method: 'POST',
  },
  vacancyAdd: {
    url: `${protocol}${host}:3001/vacancy-add`,
    method: 'POST',
  },
  vacancyByParam: {
    url: `${protocol}${host}:3001/vacancies-get-by-param`,
    method: 'POST',
  },
  vacancyDelete: {
    url: `${protocol}${host}:3001/vacancy-delete`,
    method: 'POST',
  },
  vacancyEdit: {
    url: `${protocol}${host}:3001/vacancy-edit`,
    method: 'POST',
  },
  vacancyList: {
    url: `${protocol}${host}:3001/vacancies-list`,
    method: 'POST',
  },
  duplicatesList: {
    url: `${protocol}${host}:3001/get-duplicates`,
    method: 'POST',
  },
  staffList: {
    url: `${protocol}${host}:3001/staff-list`,
    method: 'POST',
  },
  applicantAdd: {
    url: `${protocol}${host}:3001/applicant-add`,
    method: 'POST',
  },
  applicantsByParam: {
    url: `${protocol}${host}:3001/applicants-get-by-param`,
    method: 'POST',
  },
  crm_emailSend: {
    url: `https://cloud-crm.in.ua:3001/api/emails/send-from-cloudjob`,
    method: 'POST',
  },
  crm_getVacancies: {
    url: `https://cloud-crm.in.ua:3001/api/nationalities-existing`,
    method: 'GET',
  },
  crm_addCandidate: {
    url: `https://cloud-crm.in.ua:3001/api/vacancies/add-candidate`,
    method: 'POST',
  },
};
