/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import uk from 'date-fns/esm/locale/uk/index';
import ContextProvider from './context/index.tsx';
import Error from './components/common/Error.tsx';
import Preloader from './components/common/Preloader.tsx';
import router from './router';
import Success from './components/common/Success'
import './App.css'

const App = () => (
  <div>
    <ContextProvider>
      <LocalizationProvider dateAdapter={ AdapterDateFns } adapterLocale={ uk }>
        <RouterProvider router={ router } />
        <Error />
        <Success />
        <Preloader />
      </LocalizationProvider>
    </ContextProvider>
  </div>
  );

export default App;
