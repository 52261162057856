import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Female, Male } from '@mui/icons-material';
import endpoints from '../requests/endpoints'
import { formatNumber } from './phoneFormatter';
import {
  formatDateToServer,
  formatServerToClient,
  formatServerToData,
  getAgeFromServerFormat,
} from './dateFormatters';
import useFetch from '../hooks/useFetch'
import { useContekst } from '../context';

/**
 * @param {{ value: 'yyyy-MM-dd'}} param 
 * @returns {'dd.MM.yyyy'}
 */
export const dateFormatter = param => {
  if (!param?.value) return false
  return formatServerToClient(new Date(param?.value))
};

/**
 * @param {{ value: 'yyyy-MM-dd'}} param 
 * @returns {'dd.MM.yyyy'}
 */
export const ageFormatter = param => getAgeFromServerFormat(param?.data?.birthDate);

/**
 * @param {{ value: String | Number}} param 
 * @returns {true | false}
 */
export const trueFalseFormatter = value => (
  <input type='checkbox' defaultChecked={value === 1} />
);

/**
 * @param {vacancyType} param 
 * @returns {true | false}
 */
export const ActiveFormatter = value => {
  const fetch = useFetch();
  const context = useContekst();
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(value?.isActive);

  const onClose = () => {
    setIsOpen(false);
  };

  const changeToggle = () => {
    setIsOpen(true);
  };

  const onSubmit = async () => {
    const body = {
      id: value?.id,
      isActive: !active
    };
    const response = await fetch(endpoints.vacancyEdit.url, endpoints.vacancyEdit.method, body, false);
    if (response.status === 'success') {
      context.setSuccess(`You have successfully ${active ? 'deactevated' : 'activated'} the vacancy`);
      setActive(prev => (!prev));
      onClose();
    }
  };

  return (
    <>
      <input onChange={changeToggle} type='checkbox' checked={active} />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          {active ? 'Deactivation' : 'Activation'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {`Do you really want to ${!active ? 'activate' : 'deactivate'} the vacancy?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * @param {vacancyType} param 
 * @returns {true | false}
 */
export const CloseFormatter = value => {
  const [isOpen, setIsOpen] = useState(false);
  const [closed, setClosed] = useState(new Date >= formatServerToData(value?.closedAt));
  const [closedBy, setClosedBy] = useState('Joiner');
  const fetch = useFetch();
  const context = useContekst();

  const onClose = () => {
    setIsOpen(false);
  };

  const changeToggle = (event) => {
    setIsOpen(true);
  };

  const onSubmit = async () => {
    const body = {
      id: value?.id,
      closetAt: formatDateToServer(new Date(new Date().setDate(new Date().getDate() + 3))),
      closedBy,
    };
    const response = await fetch(endpoints.vacancyEdit.url, endpoints.vacancyEdit.method, body, false);
    if (response.status === 'success') {
      context.setSuccess(`You have successfully ${closed ? 'openned' : 'closed'} the vacancy`);
      setClosed(prev => (!prev));
      onClose();
    }
  };

  return (
    <>
      <input onChange={changeToggle} type='checkbox' checked={closed} />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          {!closed ? 'Closing' : 'Opening'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {!closed && 'Who did you close the vacancy by?'}
            {closed && 'Do you really want to open the vacancy?'}
          </Typography>
          {!closed && (
            <TextField
              select
              sx={{ minWidth: '100%', mt: '1rem' }}
              value={closedBy}
              onChange={(event) => setClosedBy(event.target.value)}
            >
              <MenuItem value='Joiner'>
                Joiner
              </MenuItem>
              <MenuItem value='Ex'>
                Ex
              </MenuItem>
              <MenuItem value='Other company'>
                Other company
              </MenuItem>
            </TextField>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * @param {{ value: String | Number}} param 
 * @returns {true | false}
 */
export const genderFormatter = value => (
  value === 'M' ? <Male /> : <Female />
);

/**
 * @param {{ value: String}} param 
 * @returns {JSX}
 */
export const phoneFormatter = (value) => (
  <Link href={`tel:${value}`}>{formatNumber(value, '+### (##) ##-##-###')}</Link>
);

/**
 * @param {{ value: String}} param 
 * @returns {JSX}
 */
export const emailFormatter = (value) => (
  <Link href={`mailto:${value}`}>{value}</Link>
);