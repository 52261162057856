import { styled } from '@mui/material';

const Page = styled( 'div' )( {
  width: '99vw',
  overflowX: 'hidden',
  overflowY: 'auto',
  margin: -10,
  display: 'flex',
  flexDirection: 'column'
} );

export default Page;