import {
  Add as AddIcon,
  BorderColor,
  Clear,
  Search as SearchIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import AddAndEditModal from './AddAndEditModal';
import BasicToolbar from '../common/BasicToolbar'
import DeleteModal from './DeleteModal';

type propsType = {
  modal: null | string,
  selectedRows: null | [ {} ],
  setModal: Dispatch<SetStateAction<string | null>>
};

const Toolbar: React.FC<propsType> = ( { modal, selectedRows, setModal } ) => {
  const onOpen = ( param: null | string ) => {
    // eslint-disable-next-line no-restricted-globals
    setModal( param );
  };

  return (
    <>
      <BasicToolbar>
        <Button
          size='large'
          startIcon={ <SearchIcon /> }
          variant='contained'
          color='primary'
          onClick={ () => onOpen( 'search' ) }
        >
          Search
        </Button>
        <Button
          size='large'
          startIcon={ <AddIcon /> }
          variant='contained'
          color='primary'
          onClick={ () => onOpen( 'add' ) }
        >
          Add
        </Button>
        <Button
          disabled={ !selectedRows?.length }
          color='primary'
          onClick={ () => onOpen( 'edit' ) }
          size='large'
          startIcon={ <BorderColor /> }
          variant='contained'
        >
          Edit
        </Button>
        <Button
          disabled={ !selectedRows?.length }
          onClick={ () => onOpen( 'delete' ) }
          size='large'
          startIcon={ <Clear /> }
          variant='contained'
          color='primary'
        >
          Remove
        </Button>
      </BasicToolbar>
      <AddAndEditModal
        open={ modal === 'add' || modal === 'edit' }
        onClose={ () => onOpen( '' ) }
        toEdit={ modal === 'edit' ? selectedRows : null }
      />
      <DeleteModal
        open={ modal === 'delete' }
        onClose={ () => onOpen( '' ) }
        selectedRows={ selectedRows }
      />
    </>
  )
};

export default Toolbar;