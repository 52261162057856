import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  styled, TableContainer
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';

import CenteredText from '../common/CenteredText';
import endpoints from '../../requests/endpoints';
import useFetch from '../../hooks/useFetch';
import { useContekst } from '../../context';
import { formatServerToData } from '../../helpers/dateFormatters';
import { vacancyType } from '../../../types/types';
import Application from './Application';
import ApplyButton from '../common/ApplyButton';
import ApplyButtonContainer from '../common/ApplyButtonContainer';
import ApplicationFooter from '../common/ApplicationFooter';
import ApplicationHeader from '../common/ApplicationHeader';
import ApplicationMain from '../common/ApplicationMain';
import ApplicationMainContainer from '../common/ApplicationMainContainer';
import Page from '../common/Page';

const image = require( '../../assets/imgVacancy.jpeg' );
const pax2 = require( '../../assets/pax2.jpg' );
const pax3 = require( '../../assets/pax3.jpg' );
const other = require( '../../assets/CREW_BOAT.jpg' );
const dry = require( '../../assets/DRY_BULKER_VESSEL.jpg' );
const offShore = require( '../../assets/PSV_OFFSHORE_VSL.jpg' );
const pax = require( '../../assets/PAX_CRUISE_VESSEL.jpg' );
const wet = require( '../../assets/WET_TANKER.jpg' );
const gas = require( '../../assets/LNG_TANKER_GAS.jpg' );
const ferry = require( '../../assets/FERRY.jpg' );
const noExp = require( '../../assets/Transshipment_Crane_Barge.jpg' );

const Table = styled( 'table' )( {
  minWidth: '100%',
  marginBottom: '10rem',
} );

const TableRow = styled( 'tr' )( {
  borderWidth: 3,
  paddingTop: 20,
  paddingBottom: 20,
} );

const TableCell = styled( 'td' )( {
  borderTop: '0.25rem solid lightgrey',
  textAlign: 'center',
  color: 'rgb(33,45,94)',
  fontFamily: 'sans-serif',
  fontWeight: '500',
  fontSize: 18,
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
} );

const Container = styled( 'div' )( {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Roboto',
} );

const ContainerInner = styled( 'div' )( {
  width: '80%',
  height: '100%'
} );

const Filtered = styled( 'span' )( {
  '-webkit-filter': 'grayscale(100%)',
  'filter': 'grayscale(100%)'
} );

type bodyType = {
  LastName?: string
  FirstName?: string
  Email?: string
  BirthDate?: string
  EditedBy: number
  Phone?: string
  CreatedBy: number
  Rank: string
  PoolId: number
  cv?: any
};

const LoadingContainer = styled( 'div' )( {
  width: '100vw',
  height: '100vh',
  marginTop: -10,
  marginLeft: -10,
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgb(242,184,47, 0.3)'
} );

const Loading = styled( CircularProgress )( {
  color: 'rgb(33,45,94)'
} );

const ApplicationMainInner = styled( 'div' )( {
  display: 'flex',
  'flex-wrap': 'wrap',
} );

const ImageContainer = styled( 'div' )( {
  width: '100%',
  display: 'flex',
  flex: '1 1 65%',
  'flex-wrap': 'wrap',
  justifyContent: 'center',
} );

const Image = styled( 'img' )( {
  height: '90%',
  maxWidth: '100%'
} );

const Description = styled( 'div' )( {
  fontFamily: "'Roboto', 'sans-serif'",
  whiteSpace: 'pre-wrap',
  marginLeft: 'auto',
  marginRight: '5%',
  maxWidth: '95vw',
  flex: '1 1 30%',
  overflow: 'hidden',
  maxHeight: 590,
} );

const Vacancy = () => {
  const [ vacancy, setVacancy ] = useState<vacancyType>( {} );
  const [ application, setApplication ] = useState( false );
  const [ closed, setClosed ] = useState( false );
  const { id } = useParams();
  const fetch = useFetch();
  const [ actualVacancies, setActualVacancies ] = useState( [] );
  const context = useContekst();

  const onApply = async () => {
    setApplication( true );
  };

  const images = [
    { id: 'OTHER', image: other },
    { id: 'DRY', image: dry },
    { id: 'OFFSHORE', image: offShore },
    { id: 'PAX', image: pax },
    { id: 'WET', image: wet },
    { id: 'GAS', image: gas },
    { id: 'FERRY', image: ferry },
    { id: 'NO_EXP', image: noExp },
  ];

  const getVacancy = async () => {
    const response = await fetch( endpoints.vacancyByParam.url, endpoints.vacancyByParam.method, { id }, false );
    if ( response.status === 'success' ) {
      if ( !response.data.getVacanciesByParam.length ) {
        context.setError( 'Sorry, we could not find any vacancies' );
        setClosed( true );
      } else {
        const data: vacancyType = response?.data?.getVacanciesByParam[ 0 ];
        document.title = data?.title || 'Unknown vacancy';
        setVacancy( data );
        if ( !data?.isActive || new Date >= formatServerToData( data?.closedAt ) ) {
          setClosed( true );
        }
      }
    }
  };

  const getImage = () => {
    if ( vacancy?.image ) {
      return vacancy.image
    }
    if ( vacancy.vesselTypeId ) {
      return images.find( el => el.id === vacancy.vesselType )?.image
    }
    return other
  }

  const getVacancies = useCallback( async () => {
    setActualVacancies( [ {} ] )
    const res = await fetch( endpoints.vacancyList.url, endpoints.vacancyList.method, undefined, true );
    if ( res.status === 'success' ) {
      const allActiveVacancies = res.data.vacancies.filter( ( el: vacancyType ) => {
        if ( el.isActive || new Date <= formatServerToData( el.closedAt ) ) {
          return true
        }
        return false
      } );
      setActualVacancies( allActiveVacancies.slice( 0, 5 ) );
    }
  }, [] );

  useEffect( () => {
    if ( !actualVacancies.length ) {
      getVacancies();
    }
  }, [ actualVacancies, getVacancies ] );

  useEffect( () => {
    document.title = 'Vacancy';
    getVacancy();
  }, [] );

  if ( !Object.keys( vacancy ).length ) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if ( closed ) {
    let emailByCompany = vacancy.emailTo.includes('@csm-ua.com') ? 'forcv@csm-ua.com' : 'forcv@eurocrew-ua.com';
    const siteByCompany = vacancy.emailTo.includes('@csm-ua.com') ? 'csm-ua.com' : 'eurocrew-ua.com';
    if(vacancy.pool === 'CSM_PAX'){
      emailByCompany = 'pax@csm-ua.com'
    }
    return (


          <Page>
      <ApplicationHeader onGoBack={ application ? () => setApplication( false ) : undefined } />
      <ApplicationMainContainer>
        { !application && (
            <ApplicationMain>
            <ApplicationMainInner className='mediaMy'>
              <ImageContainer>
                <Filtered>
                <Image
                    loading='lazy'
                    alt='Ship'
                    src={ getImage() }
                />
                </Filtered>
              </ImageContainer>
              <CenteredText><h4>Good Day! Thank you for applying to our Company!
                Current position is closed at the moment.
                  </h4></CenteredText>
              <Container>
                <ContainerInner>
              <p> Feel free to send us your CV to email:  <a href={`mailTo:${emailByCompany}`}> {emailByCompany} </a></p>
                  <p>You can always check availability of open vacancies on our site: <a href={`https://${siteByCompany}`}> {siteByCompany} </a></p>
                <p>For your easy reference the hot vacancies are listed below.
                  Looking forward to receive your application form.</p>

              <Table>
                <tbody>
                { !!actualVacancies?.length && !!Object.keys( actualVacancies[ 0 ] )?.length && (
                    actualVacancies.map( ( vacancy_: vacancyType ) => (
                        <TableRow key={ vacancy_.id }>
                          <TableCell sx={ { textAlign: 'start' } }>
                            { vacancy_.title }
                          </TableCell>
                          <TableCell>
                            <ApplyButton onClick={ () => window.open( `${ vacancy_?.id }`, '_blank' ) }>
                              Details
                            </ApplyButton>
                          </TableCell>
                        </TableRow>
                    ) ) ) }
                </tbody>
              </Table>
              </ContainerInner>
              </Container>
            </ApplicationMainInner>
            </ApplicationMain>
        ) }
        { application && <Application vacancy={ vacancy } onClose={ () => setApplication( false ) } /> }
      </ApplicationMainContainer>
      <ApplicationFooter />
    </Page>
    );
  }

  return (
    <Page>
      <ApplicationHeader onGoBack={ application ? () => setApplication( false ) : undefined } />
      <ApplicationMainContainer>
        { !application && (
          <ApplicationMain>
          <ApplicationMainInner>
            <ImageContainer>
              <Image
                loading='lazy'
                alt='Ship'
                src={ getImage() }
              />
            </ImageContainer>
            <Description dangerouslySetInnerHTML={ { __html: decodeURI( vacancy?.description ) } } />
          </ApplicationMainInner>
            <ApplyButtonContainer>
              <ApplyButton onClick={ onApply } style={{width:'100%', margin:'1% 2%'}}>
                APPLY !
              </ApplyButton>
            </ApplyButtonContainer>
          </ApplicationMain>
        ) }
        { application && <Application vacancy={ vacancy } onClose={ () => setApplication( false ) } /> }
      </ApplicationMainContainer>
      <ApplicationFooter />
    </Page>
  )
};

export default Vacancy;
