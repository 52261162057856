/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { styled, useColorScheme } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Container = styled('div')({
  flex: 1,
  width: '100%',
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 20,
});

const BasicLayoutGridContainer = ({ children }) => {
  const { mode } = useColorScheme();
  return (
    <Container className={`ag-theme-alpine${mode === 'dark' ? '-dark' : ''}`} >
      {children}
    </Container>
  );
};

export default BasicLayoutGridContainer;
