import { styled } from '@mui/material';
import img from '../../assets/bg.png';
// import imgDark from '../../assets/bg-dark.jpg';

export default styled('div')(() => ({
  minHeight: 'inherit',
  backgroundImage: `url(${img})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
}));
